import React, { useEffect, useMemo, useState, useContext } from 'react';
const Reader = () => {
  
  const [txt, setTxt] = useState("please put button");

  useEffect(() => {
    const fetchData = async () => {

    }

    fetchData();
  }, []);

  const ReadNfcCommand = (async() => {
    if ('NDEFReader' in window) {
      try {
        // eslint-disable-next-line no-undef
        const reader = new NDEFReader();
        await reader.scan();
        setTxt("scan started");
        
        reader.addEventListener("error", () => {
          setTxt("error scan");
        });
        
        reader.addEventListener("reading", ({ message, serialNumber }) => {
          setTxt(`> Serial Number: ${serialNumber}`);
          if (message.records.length == 0) {
            return;
          }

          const record = message?.records[0];
          const { data, encoding, recordType } = record;
          setTxt(`> Serial Number: ${serialNumber} recordType: ${recordType}`);
          // if (recordType === "text") {
          //   const textDecoder = new TextDecoder(encoding);
          //   const text = textDecoder.decode(data);
          // }
        });
      } catch (ex) {
        setTxt(ex.message);
      }
    } else {
      setTxt("NFCがサポートされていません");
    }
  });

  const WriteNfcCommand = (async() => {
    if ('NDEFReader' in window) {
      setTxt("write start");
      try {
        // eslint-disable-next-line no-undef
        const ndefWriter = new NDEFReader();
        const customMessage = {
          records: [
            {
              recordType: "url",
              data: "https://daira.blog?id=" + new Date().toISOString().slice(11, 16).replace(/:/g, "")
            }
          ]
        };
  
        // NFCタグにカスタマイズされたNDEFメッセージを書き込む
        await ndefWriter.write(customMessage);
        setTxt("NFCタグにカスタマイズされたNDEFメッセージを書き込みました");
      } catch (ex) {
        alert("error");
        setTxt(ex.message);
      }
    } else {
      setTxt("このブラウザではNFCをサポートしていません");
    }
  });

  return (
    <div>
      <button onClick={() => ReadNfcCommand()}>読み込み</button>
      <button onClick={() => WriteNfcCommand()}>書き込み</button>
      <div>{txt}</div>

    </div>
  )
}

export default Reader;