// ------------------------------------------------------------------------------------
// 環境
// ------------------------------------------------------------------------------------
/**
 * @typedef {Object} Environment
 * @property {number} LOCAL - ローカル環境
 * @property {number} DEVELOP - 開発/検証環境
 * @property {number} PRODUCTION - 本番環境
 */
export const ENVIRONMENT = {
  LOCAL: "local",
  DEVELOP: "develop",
  PRODUCTION: "product",
};

// ------------------------------------------------------------------------------------
// プラン
// ------------------------------------------------------------------------------------
/**
 * @typedef {Object} PlanType
 * @property {number} DEMO - デモ
 * @property {number} LITE - ライト
 * @property {number} BASIC - プロ
 * @property {number} PREMIUM - プレミアム
 * @property {number} ENTERPRISE - エンタープライズ
 */
export const PLAN_TYPE = {
  DEMO: 0,
  LITE: 1,
  BASIC: 2,
  PREMIUM: 3,
  ENTERPRISE: 4,
};

// ------------------------------------------------------------------------------------
// アカウント
// ------------------------------------------------------------------------------------
/**
 * @typedef {Object} UserType
 * @property {number} OWNER - CLASSIXアカウント
 * @property {number} ADMIN - 管理者アカウント
 * @property {number} STAFF - スタッフアカウント
 */
export const USER_TYPE = {
  OWNER: 1,
  ADMIN: 2,
  STAFF: 3
};

// ------------------------------------------------------------------------------------
// 会社
// ------------------------------------------------------------------------------------
/**
 * @typedef {Object} ContractStatus
 * @property {number} ALL - 全て
 * @property {number} ACTIVE - 契約中
 * @property {number} PREPARING - 契約期間前
 * @property {number} INACTIVE - 契約期間終了
 */
export const CONTRACT_STATUS = {
  ALL: 0,
  ACTIVE: 1,
  PREPARING: 2,
  INACTIVE: 3
};

// ------------------------------------------------------------------------------------
// ユーザー
// ------------------------------------------------------------------------------------
/**
 * @typedef {Object} StaffStatus
 * @property {number} ALL - 全て
 * @property {number} ACTIVE - 利用中
 * @property {number} SCHEDULED - 準備中
 * @property {number} INACTIVE - 停止中
 */
export const STAFF_STATUS = {
  ALL: 0,
  ACTIVE: 1,
  SCHEDULED: 2,
  INACTIVE: 3
};

/**
 * @typedef {Object} GroupStatus
 * @property {number} ALL - 全て
 * @property {number} AFFILIATION - 所属
 * @property {number} NOT_AFFILIATION - 未所属
 */
export const GROUP_STATUS = {
  ALL: 0,
  AFFILIATION: 1,
  NOT_AFFILIATION: 2,
};

/**
 * @typedef {Object} SexType
 * @property {number} MALE - 男性
 * @property {number} FEMALE - 女性
 * @property {number} NONE - 無回答
 */
export const SEX_TYPE = {
  MALE: 1,
  FEMALE: 2,
  NONE: 3,
};

// ------------------------------------------------------------------------------------
// 研修
// ------------------------------------------------------------------------------------
/**
 * @typedef {Object} StatusTypeClassix
 * @property {number} ALL - 全て
 * @property {number} ACTIVE - 公開中
* @property {number} NOTSETTING - 公開待機中 
* @property {number} SCHEDULE - 公開予定
 * @property {number} INACTIVE - 公開終了
 */
export const STATUS_TYPE_CLASSIX = {
  ALL: 0,
  ACTIVE: 1,
  NOTSETTING: 2,
  SCHEDULE: 3,
  INACTIVE: 4
};

/**
 * @typedef {Object} StatusType
 * @property {number} ALL - 全て
 * @property {number} ACTIVE - 公開中
 * @property {number} UNRELEASE - 公開予定
 * @property {number} INACTIVE - 公開終了
 */
export const STATUS_TYPE = {
  ALL: 0,
  ACTIVE: 1,
  UNRELEASE: 2,
  INACTIVE: 3
};

/**
 * @typedef {Object} PublishScope
 * @property {number} ALL - 全員
 * @property {number} LIMITED - 限定公開
 */
export const PUBLISH_SCOPE = {
  ALL: 1,
  LIMITED: 2
};

/**
 * @typedef {Object} FileType
 * @property {number} PDF - PDF
 * @property {number} VIDEO - 動画
 */
export const FILE_TYPE = {
  PDF: 1,
  VIDEO: 2
};

/**
 * @typedef {Object} JobStatus
 * @property {number} ALL - 全て
 * @property {number} NOT_END - 未受講
 * @property {number} END - 受講済
 */
export const JOB_STATUS = {
  ALL: 0,
  NOT_END: 1,
  END: 2
};

/**
 * @typedef {Object} StaffJobStatus
 * @property {number} ALL - 全て
 * @property {number} NOT_END - 未受講
 * @property {number} END - 受講済
 */
export const STAFF_JOB_STATUS = {
  ALL: 0,
  JOB_NOT_END: 1,
  JOB_END: 2,
  TEST_NOT_END: 3,
  TEST_END: 4,
  SIGN_NOT_END: 5,
  SIGN_END: 6,
};

// ------------------------------------------------------------------------------------
// テスト
// ------------------------------------------------------------------------------------
/**
 * @typedef {Object} TestStatus
 * @property {number} SETTING - 設定済
 * @property {number} UNSETTING - 未設定
 * @property {number} NONE - なし
 */
export const TEST_STATUS = {
  SETTING: 1,
  UNSETTING: 2,
  NONE: 3
};

/**
 * @typedef {Object} AnswerType
 * @property {number} SINGLE_CHOICE - 単一選択式
 * @property {number} MULTIPLE_CHOICE - 複数選択式
 */
export const ANSWER_TYPE = {
  SINGLE_CHOICE: 1,
  MULTIPLE_CHOICE: 2
};

/**
 * @typedef {Object} TEST_MODE
 * @property {number} GRADING - 採点モード
 * @property {number} LEARNING - 受講モード
 * @property {number} PREVIEW - プレビューモード
 */
export const TEST_MODE = {
  GRADING: 1,    // 採点モード
  LEARNING: 2,   // 受講モード
  PREVIEW: 3,    // プレビューモード
};

/**
 * @typedef {Object} ESignatureStatus
 * @property {number} ALL - 全て
 * @property {number} USE - 使用中
 * @property {number} NOT_USE - 未使用
 */
export const E_SIGNATURE_STATUS = {
  ALL: 0,
  USE: 1,
  NOT_USE: 2,
};

// ------------------------------------------------------------------------------------
// 電子サイン
// ------------------------------------------------------------------------------------
/**
 * @typedef {Object} ESignatureDownloadType
 * @property {number} ALL - 全て
 * @property {number} STAFF - スタッフ
 */
export const E_SIGNATURE_DOWNLOAD_TYPE = {
  ALL: 1,
  STAFF: 2,
};

/**
 * @typedef {Object} ESignOption
 * @property {number} NONE - 未送信or対象外
 * @property {number} UNSIGN - 電子サイン未署名
 * @property {number} SIGNED - 電子サイン署名済み
 */
export const ESIGN_OPTION = {
  NONE: 1,    // 未送信or対象外
  UNSIGN: 2,  // 電子サイン未署名
  SIGNED: 3,  // 電子サイン署名済み
};

// ------------------------------------------------------------------------------------
// 通知
// ------------------------------------------------------------------------------------
/**
 * @typedef {Object} SendToType
 * @property {number} ALL - 全員
 * @property {number} ADMIN - 管理者
 * @property {number} STAFF - スタッフ
 * @property {number} GROUP - グループ
 * @property {number} PERSONAL - スタッフ個人
 */
export const SEND_TO_TYPE = {
  ALL: 1,
  ADMIN: 2,
  STAFF: 3,
  GROUP: 4,
  PERSONAL: 5,
};

/**
 * @typedef {Object} SendToTypeOwner
 * @property {number} ALL - 全員
 * @property {number} ADMIN - 管理者
 * @property {number} STAFF - スタッフ
 */
export const SEND_TO_TYPE_OWNER = {
  ALL: 0,
  ALL_USER: 1,
  ADMIN: 2,
};

/**
 * @typedef {Object} SendToTypeAdmin
 * @property {number} ALL - 全員
 * @property {number} GROUP - グループ
 * @property {number} PERSONAL - スタッフ個人
 */
export const SEND_TO_TYPE_ADMIN = {
  ALL: 0,
  STAFF: 1,
  GROUP: 2,
  PERSONAL: 3,
};


/**
 * @typedef {Object} SendToType
 * @property {number} ALL - 全て
 * @property {number} UNREAD - 未読
 * @property {number} READ - 既読
 */
export const READ_STATUS = {
  ALL: 0,
  UNREAD: 1,
  READ: 2,
};

// ------------------------------------------------------------------------------------
// APIレスポンス
// ------------------------------------------------------------------------------------
/**
 * @typedef {Object} Response
 * @property {number} SUCCESS - 成功
 * @property {number} USER_ERROR - ユーザーエラー
 * @property {number} PROGRESS - 実行中
 * @property {number} SESSION_EXPIRED - セッションタイムアウト
 */
export const RESPONSE = {
  SUCCESS: 0,
  USER_ERROR: 1,
  PROGRESS: 2,
  SESSION_EXPIRED: 3
};

// ------------------------------------------------------------------------------------
// モーダル
// ------------------------------------------------------------------------------------
/**
 * @typedef {Object} ModalType
 * @property {number} BASE - 基本モーダル
 * @property {number} PDF - PDFモーダル
 * @property {number} VIMEO - Vimeoモーダル
 * @property {number} PASSWORD - パスワードモーダル
 * @property {number} SELECT - 選択モーダル
 */
export const MODAL_TYPE = {
  BASE: 0,
  PDF: 1,
  VIMEO: 2,
  PASSWORD: 3,
  SELECT: 4,
};

/**
 * @typedef {Object} ModalSubmitText
 * @property {number} CANCEL - キャンセル
 * @property {number} COMPLETE - 研修完了
 */
export const MODAL_SUBMIT_TEXT = {
  CANCEL: 'キャンセル',
  COMPLETE: '研修完了',
};