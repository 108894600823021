/* ==================== */
/* 最大文字数 */
/* ==================== */
export const MaxLength = {

  // ------------------------------------------------------------------------------------
  // 通知
  // ------------------------------------------------------------------------------------
  /** @property {int} NOTIFICATION_TITLE_MAX_LENGTH - 'タイトル'*/
  NOTIFICATION_TITLE_MAX_LENGTH: 30,
  /** @property {int} NOTIFICATION_CONTENTS_MAX_LENGTH - '内容'*/
  NOTIFICATION_CONTENTS_MAX_LENGTH: 2000,
  
  // ------------------------------------------------------------------------------------
  // グループ
  // ------------------------------------------------------------------------------------
  /** @property {int} GROUP_NAME_MAX_LENGTH - 'グループ名'*/
  GROUP_NAME_MAX_LENGTH: 10,
  /** @property {int} GROUP_NAME_KANA_MAX_LENGTH - 'グループ名（かな）'*/
  GROUP_NAME_KANA_MAX_LENGTH: 20,
  
  // ------------------------------------------------------------------------------------
  // 顧客
  // ------------------------------------------------------------------------------------
  /** @property {int} COMPANY_NAME_MAX_LENGTH - '会社名'*/
  COMPANY_NAME_MAX_LENGTH: 20,
  
  // ------------------------------------------------------------------------------------
  // アカウント
  // ------------------------------------------------------------------------------------
  /** @property {int} PAYEE_MAX_LENGTH - '振込先'*/
  PAYEE_MAX_LENGTH: 100,
  /** @property {int} BUSINESS_NUMBER_MAX_LENGTH - '適格請求書発行事業者登録番号'*/
  BUSINESS_NUMBER_MAX_LENGTH: 13,

  // ------------------------------------------------------------------------------------
  // 電子サイン
  // ------------------------------------------------------------------------------------
  /** @property {int} ESIGN_DOCUMENT_MAX_COUNT - '最大文書数'*/
  ESIGN_DOCUMENT_MAX_COUNT: 5,
  /** @property {int} ESIGN_TEMPLATE_ID_MAX_LENGTH - 'テンプレートID'*/
  ESIGN_TEMPLATE_ID_MAX_LENGTH: 20,
  /** @property {int} ESIGN_TEMPLATE_NAME_MAX_LENGTH - 'テンプレート名'*/
  ESIGN_TEMPLATE_NAME_MAX_LENGTH: 20,
  /** @property {int} ESIGN_TEMPLATE_NAME_KANA_MAX_LENGTH - 'テンプレート名（かな）'*/
  ESIGN_TEMPLATE_NAME_KANA_MAX_LENGTH: 40,

  // ------------------------------------------------------------------------------------
  // 研修
  // ------------------------------------------------------------------------------------
  /** @property {int} JOB_NAME_MAX_LENGTH - '研修名'*/
  JOB_NAME_MAX_LENGTH: 20,
  /** @property {int} FILE_NAME_MAX_LENGTH - 'ファイル名'*/
  FILE_NAME_MAX_LENGTH: 20,
  /** @property {int} FILE_NAME_MAX_SIZE_MB - 'ファイルサイズ(MB)'*/
  FILE_NAME_MAX_SIZE_MB: 50,
  /** @property {int} VIMEO_ID_MAX_LENGTH - 'VIMEOID'*/
  VIMEO_ID_MAX_LENGTH: 15,
  /** @property {int} QUESTION_TEXT_MAX_LENGTH - '問題文'*/
  QUESTION_TEXT_MAX_LENGTH: 60,
  /** @property {int} CHOICE_TEXT_MAX_LENGTH - '選択肢'*/
  CHOICE_TEXT_MAX_LENGTH: 60,
  /** @property {int} QUESTION_MAX_COUNT - '問題'*/
  QUESTION_MAX_COUNT: 10,
  /** @property {int} CHOICE_MAX_COUNT - '選択肢'*/
  CHOICE_MAX_COUNT: 4,

  // ------------------------------------------------------------------------------------
  // プラン
  // ------------------------------------------------------------------------------------
  /** @property {int} PLAN_PREMIUM_MAX_STAFF_COUNT - 'PREMIUMプランの最大人数'*/
  PLAN_PREMIUM_MAX_STAFF_COUNT: 300,
  /** @property {int} PLAN_PREMIUM_MAX_ADMIN_COUNT - 'PREMIUMプランの最大管理者数'*/
  PLAN_PREMIUM_MAX_ADMIN_COUNT: 6,
  
  // ------------------------------------------------------------------------------------
  // ユーザー関連
  // ------------------------------------------------------------------------------------
  /** @property {int} USER_NAME_MAX_LENGTH - 'ユーザー名'*/
  USER_NAME_MAX_LENGTH: 10,
  /** @property {int} USER_NAME_KANA_MAX_LENGTH - 'ユーザー名（かな）'*/
  USER_NAME_KANA_MAX_LENGTH: 20,
  /** @property {int} WORK_NAME_MAX_LENGTH - '業務内容'*/
  WORK_NAME_MAX_LENGTH: 20,
  /** @property {int} TELEPHONE_MAX_LENGTH - '電話番号'*/
  TELEPHONE_MAX_LENGTH: 11,
  /** @property {int} POSTAL_CODE_MAX_LENGTH - '郵便番号'*/
  POSTAL_CODE_MAX_LENGTH: 7,
  /** @property {int} ADDRESS_MAX_LENGTH - '住所'*/
  ADDRESS_MAX_LENGTH: 30,
  /** @property {int} NOTE_MAX_LENGTH - '備考'*/
  NOTE_MAX_LENGTH: 100,
  /** @property {int} MAIL_MAX_LENGTH - 'メールアドレス'*/
  MAIL_MAX_LENGTH: 255,
  /** @property {int} PASSWORD_MAX_LENGTH - 'パスワード'*/
  PASSWORD_MAX_LENGTH: 16,

  // ------------------------------------------------------------------------------------
  // 請求関連
  // ------------------------------------------------------------------------------------
  /** @property {int} INVOICE_NUMBER_MAX_LENGTH - '請求書番号'*/
  INVOICE_NUMBER_MAX_LENGTH: 15,
  /** @property {int} ITEM_NAME_MAX_LENGTH - '請求明細名'*/
  ITEM_NAME_MAX_LENGTH: 20,
  /** @property {int} ITEM_QUANTITY_MAX_LENGTH - '請求明細数量'*/
  ITEM_QUANTITY_MAX_LENGTH: 2,
  /** @property {int} ITEM_UNIT_PRICE_MAX_LENGTH - '請求明細単価'*/
  ITEM_UNIT_PRICE_MAX_LENGTH: 7,
  /** @property {int} MAX_PAYMENT_ITEM_COUNT - '請求項目数'*/
  MAX_PAYMENT_ITEM_COUNT: 5,
  /** @property {int} ITEM_QUANTITY_MIN - '請求明細最小数量'*/
  ITEM_QUANTITY_MIN: 1,
  /** @property {int} ITEM_QUANTITY_MAX - '請求明細最大数量'*/
  ITEM_QUANTITY_MAX: 99,
  /** @property {int} ITEM_UNIT_PRICE_MIN - '請求明細最小単価'*/
  ITEM_UNIT_PRICE_MIN: 1,
  /** @property {int} ITEM_UNIT_PRICE_MAX - '請求明細最大単価'*/
  ITEM_UNIT_PRICE_MAX: 9999999,
  /** @property {int} MAX_TAX_RATE - '最大税率'*/
  MAX_TAX_RATE: 100,
  /** @property {int} MIN_TAX_RATE - '最小税率'*/
  MIN_TAX_RATE: 0,
}

/* ==================== */
/* メッセージ */
/* ==================== */
export const Message = {
  // ------------------------------------------------------------------------------------
  // 研修
  // ------------------------------------------------------------------------------------
  /** @property {string} CONFIRM_NOW_START_JOB - 'すぐに研修の公開を開始します。'*/
  CONFIRM_NOW_START_JOB: 'すぐに研修の公開を開始します。',
  /** @property {string} CONFIRM_SCHEDULE_START_JOB - '公開開始日に自動的に公開を開始します。\n後から公開開始日は変更できません。'*/
  CONFIRM_SCHEDULE_START_JOB: '公開開始日に自動的に公開を開始します。\n後から公開開始日は変更できません。',
  /** @property {string} CONFIRM_STOP_JOB - '研修の公開を停止してもよろしいですか？'*/
  CONFIRM_STOP_JOB: '研修の公開を停止します。\n\n※本日24:00に研修の公開が終了します。\n現在この研修を使用している会社の研修も本日で公開終了となります。\nこの処理は元には戻せません。',
  /** @property {string} CONFIRM_STOP_JOB_ADMIN - '研修の公開を停止します。\n\n※この処理は元には戻せません。'*/
  CONFIRM_STOP_JOB_ADMIN: '研修の公開を停止します。\n\n※この処理は元には戻せません。',
  
  // ------------------------------------------------------------------------------------
  // 顧客
  // ------------------------------------------------------------------------------------
  /** @property {string} CONFIRM_NOW_ADD_CUSTOMER - '現在から契約を開始します。（一度開始した契約の一時停止はできません。）'*/
  CONFIRM_NOW_ADD_CUSTOMER: '今すぐに契約を開始します。\n（一度開始した契約の一時停止はできません。）',
  /** @property {string} CONFIRM_SCHEDULE_ADD_CUSTOMER - '契約開始日に自動的に契約を開始します。（一度開始した契約の一時停止はできません。契約開始日までは変更が可能です。）'*/
  CONFIRM_SCHEDULE_ADD_CUSTOMER: '契約開始日に自動的に契約を開始します。\n（一度開始した契約の一時停止はできません。契約開始日までは変更が可能です。）',
  /** @property {string} CONFIRM_STOP_CONTRACT - '顧客の契約を停止してもよろしいですか？（本日24:00に契約が終了します。この処理は元には戻せません。）'*/
  CONFIRM_STOP_CONTRACT: '顧客の契約を停止してもよろしいですか？\n（本日24:00に契約が終了します。この処理は元には戻せません。）',

  // ------------------------------------------------------------------------------------
  // スタッフ
  // ------------------------------------------------------------------------------------
  /** @property {string} CONFIRM_NOW_ADD_ADMIN - '今すぐに管理者の利用が可能になります。（一度利用開始すると一時停止はできません。）'*/
  CONFIRM_NOW_ADD_ADMIN: '今すぐに管理者の利用が可能になります。\n（一度利用開始すると一時停止はできません。）',
  /** @property {string} CONFIRM_NOW_ADD_STAFF - '今すぐにスタッフの利用が可能になります。（一度利用開始すると一時停止はできません。）'*/
  CONFIRM_NOW_ADD_STAFF: '今すぐにスタッフの利用が可能になります。\n（一度利用開始すると一時停止はできません。）',
  /** @property {string} CONFIRM_SCHEDULE_ADD_STAFF - '利用開始日に自動的に利用開始が可能になります。（一度利用開始すると一時停止はできません。利用開始日までは変更が可能です。）'*/
  CONFIRM_SCHEDULE_ADD_STAFF: '利用開始日に自動的に利用開始が可能になります。\n（一度利用開始すると一時停止はできません。利用開始日までは変更が可能です。）',
  /** @property {string} CONFIRM_NOW_STOP_ACCOUNT - '今すぐにアカウントを停止してもよろしいですか？\n（この処理は元には戻せません。）'*/
  CONFIRM_NOW_STOP_ACCOUNT: '今すぐにアカウントを停止してもよろしいですか？\n（この処理は元には戻せません。）',
  /** @property {string} CONFIRM_NOW_DELETE_ACCOUNT - '今すぐにアカウントを削除してもよろしいですか？\n（この処理は元には戻せません。）'*/
  CONFIRM_NOW_DELETE_ACCOUNT: '今すぐにアカウントを削除してもよろしいですか？\n（この処理は元には戻せません。）',

  // ------------------------------------------------------------------------------------
  // メール
  // ------------------------------------------------------------------------------------
  /** @property {string} CONFIRM_RESEND_INIT_MAIL - '初期設定用メールを再送信しますか？'*/
  CONFIRM_RESEND_INIT_MAIL: '初期設定用メールを再送信しますか？',

  // ------------------------------------------------------------------------------------
  // ボトムシート
  // ------------------------------------------------------------------------------------
  /** @property {string} BS_RESEND_INIT_MAIL - 'メールを再送信しました'*/
  BS_RESEND_INIT_MAIL: 'メールを再送信しました',
  /** @property {string} BS_UPDATE_PASSWORD_SUCCESS - 'パスワードを更新しました'*/
  BS_UPDATE_PASSWORD_SUCCESS: 'パスワードを更新しました',
  /** @property {string} BS_INIT_PASSWORD_SUCCESS - 'パスワードの設定が完了しました'*/
  BS_INIT_PASSWORD_SUCCESS: 'パスワードの設定が完了しました',
  /** @property {string} BS_UPDATE_MAIL_SUCCESS - 'メールアドレスを更新しました'*/
  BS_UPDATE_MAIL_SUCCESS: 'メールアドレスを更新しました',
  /** @property {string} BS_RESET_RESULT_SUCCESS - '受講履歴をリセットしました'*/
  BS_RESET_RESULT_SUCCESS: '受講履歴をリセットしました',

  /** @property {string} BS_UPDATE_COMPANY_SUCCESS - '会社情報を更新しました'*/
  BS_UPDATE_COMPANY_SUCCESS: '会社情報を更新しました',

  /** @property {string} BS_ADD_CUSTOMER_SUCCESS - '顧客を追加しました'*/
  BS_ADD_CUSTOMER_SUCCESS: '顧客を追加しました',
  /** @property {string} BS_UPDATE_CUSTOMER_SUCCESS - '顧客を更新しました'*/
  BS_UPDATE_CUSTOMER_SUCCESS: '顧客を更新しました',
  /** @property {string} BS_STOP_CUSTOMER_SUCCESS - '顧客を停止しました'*/
  BS_STOP_CUSTOMER_SUCCESS: '顧客を停止しました',
    
  /** @property {string} BS_ADD_JOB_SUCCESS - '研修を追加しました'*/
  BS_ADD_JOB_SUCCESS: '研修を追加しました',
  /** @property {string} BS_UPDATE_JOB_SUCCESS - '研修を更新しました'*/
  BS_UPDATE_JOB_SUCCESS: '研修を更新しました',
  /** @property {string} BS_STOP_JOB_SUCCESS - '研修の公開を停止しました'*/
  BS_STOP_JOB_SUCCESS: '研修の公開を停止しました',
  /** @property {string} BS_JOB_COMPLETE_SUCCESS - '受講完了しました'*/
  BS_JOB_COMPLETE_SUCCESS: '研修の受講が完了しました',
    
  /** @property {string} BS_LC_SETTING_SUCCESS - 'テストを更新しました'*/
  BS_LC_SETTING_SUCCESS: 'テストを更新しました',
  /** @property {string} BS_LC_COMPLETE_SUCCESS - 'テストを完了しました'*/
  BS_LC_COMPLETE_SUCCESS: 'テストを完了しました',
  
  /** @property {string} BS_ADMIN_ADD_SUCCESS - '管理者を追加しました'*/
  BS_ADMIN_ADD_SUCCESS: '管理者を追加しました',
  /** @property {string} BS_ADMIN_UPDATE_SUCCESS - '管理者を更新しました'*/
  BS_ADMIN_UPDATE_SUCCESS: '管理者を更新しました',
  /** @property {string} BS_ADMIN_STOP_SUCCESS - '管理者を停止しました'*/
  BS_ADMIN_STOP_SUCCESS: '管理者を停止しました',
  /** @property {string} BS_ADMIN_DELETE_SUCCESS - '管理者を削除しました'*/
  BS_ADMIN_DELETE_SUCCESS: '管理者を削除しました',

  /** @property {string} BS_STAFF_ADD_SUCCESS - 'スタッフを追加しました'*/
  BS_STAFF_ADD_SUCCESS: 'スタッフを追加しました',
  /** @property {string} BS_STAFF_UPDATE_SUCCESS - 'スタッフを更新しました'*/
  BS_STAFF_UPDATE_SUCCESS: 'スタッフを更新しました',
  /** @property {string} BS_STAFF_STOP_SUCCESS - 'スタッフを停止しました'*/
  BS_STAFF_STOP_SUCCESS: 'スタッフを停止しました',
  /** @property {string} BS_STAFF_DELETE_SUCCESS - 'スタッフを削除しました'*/
  BS_STAFF_DELETE_SUCCESS: 'スタッフを削除しました',
  
  /** @property {string} BS_STAFF_GROUP_ADD_SUCCESS - 'グループを追加しました'*/
  BS_STAFF_GROUP_ADD_SUCCESS: 'グループを追加しました',
  /** @property {string} BS_STAFF_GROUP_UPDATE_SUCCESS - 'グループを更新しました'*/
  BS_STAFF_GROUP_UPDATE_SUCCESS: 'グループを更新しました',
  /** @property {string} BS_STAFF_GROUP_DELETE_SUCCESS - 'グループを削除しました'*/
  BS_STAFF_GROUP_DELETE_SUCCESS: 'グループを削除しました',

  /** @property {string} BS_ESIGNATURE_ADD_SUCCESS - '電子サインを追加しました'*/
  BS_ESIGNATURE_ADD_SUCCESS: '電子サインを追加しました',
  /** @property {string} BS_ESIGNATURE_DELETE_SUCCESS - '電子サインを削除しました'*/
  BS_ESIGNATURE_DELETE_SUCCESS: '電子サインを削除しました',
};

/* ==================== */
/* エラーメッセージ */
/* ==================== */
export const ErrorMessage = {
  /** @property {string} OUT_OF_SERVICE - 'サービスが一時的に停止しています。' */
  OUT_OF_SERVICE: 'サービスが一時的に停止しています。',
  /** @property {string} OFFLINE - 'オフラインです。インターネット接続を確認してください。' */
  OFFLINE: 'オフラインです。インターネット接続を確認してください。',
  /** @property {string} NON_STATUS_ERROR - 'エラーが発生しました。' */
  NON_STATUS_ERROR: 'エラーが発生しました。',
  /** @property {string} INVALID_REQUEST - 'リクエストが不正です。' */
  INVALID_REQUEST: 'リクエストが不正です。',
  /** @property {string} SESSION_EXPIRED - 'セッション切れのため、ログイン画面に遷移します。' */
  SESSION_EXPIRED: 'セッション切れのため、ログイン画面に遷移します。',
  /** @property {string} RESOURCE_NOT_FOUND - 'リソースが見つかりませんでした。' */
  RESOURCE_NOT_FOUND: 'リソースが見つかりませんでした。',
  /** @property {string} SYSTEM_ERROR - 'システムエラーが発生しました。' */
  SYSTEM_ERROR: 'システムエラーが発生しました。',
  /** @property {string} API_ERROR - 'エラーが発生しました。リロードしてやり直してください。'*/
  API_ERROR: 'エラーが発生しました。リロードしてやり直してください。',
  /** @property {string} API_TIMEOUT - 'リクエストがタイムアウトしました。' */
  API_TIMEOUT: 'リクエストがタイムアウトしました。',
  /** @property {string} INVALID_EMAIL_OR_PASSWORD - 'メールアドレスまたはパスワードが間違っています。' */
  INVALID_EMAIL_OR_PASSWORD: 'メールアドレスまたはパスワードが間違っています。',
  /** @property {string} NOW_PASSWORD_FAILED - '現在のパスワードが間違っています。' */
  NOW_PASSWORD_FAILED: '現在のパスワードが間違っています。',
  /** @property {string} PASSWORD_UPDATE_FAILED - 'パスワードの更新に失敗しました。' */
  PASSWORD_UPDATE_FAILED: 'パスワードの更新に失敗しました。',
  /** @property {string} AUTH_INIT_PASSWORD_FAILED - 'パスワードの設定に失敗しました。再度メールのリンクからやり直してください。' */
  AUTH_INIT_PASSWORD_FAILED: 'パスワードの設定に失敗しました。再度メールのリンクからやり直してください。',
  /** @property {string} NOW_MAIL_FAILED - '現在のメールアドレスが間違っています。' */
  NOW_MAIL_FAILED: '現在のメールアドレスが間違っています。',
  /** @property {string} MAIL_CHANGE_FAILED - 'メールアドレスの更新に失敗しました。' */
  MAIL_CHANGE_FAILED: 'メールアドレスの更新に失敗しました。',
  /** @property {string} NOT_FOUND - '必要な値が取得できませんでした。リロードしてやり直してください。' */
  NOT_FOUND: '必要な値が取得できませんでした。リロードしてやり直してください。',
  /** @property {string} AUTH_CHECK_TOKEN_FAILED - '有効期限切れです。管理者からアカウント設定メールの再送信を依頼してください。' */
  AUTH_CHECK_TOKEN_FAILED: '有効期限切れです。管理者からアカウント設定メールの再送信を依頼してください。',
  /** @property {string} JOB_NOT_FOUND - '研修が見つかりませんでした。' */
  JOB_NOT_FOUND: '研修が見つかりませんでした。',
  /** @property {string} TEST_NOT_FOUND - 'テストが見つかりませんでした。' */
  TEST_NOT_FOUND: 'テストが見つかりませんでした。',
  /** @property {string} RESET_RESULT_FAILED - '受講履歴のリセットに失敗しました。' */
  RESET_RESULT_FAILED: '受講履歴のリセットに失敗しました。',
}
