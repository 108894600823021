import { ANSWER_TYPE, PUBLISH_SCOPE, SEND_TO_TYPE, STATUS_TYPE, USER_TYPE } from "../const/Enum";
import { MaxLength } from "../const/Constant";

/**
 * 入力値のバリデーションチェック
 * @param {string} input - 入力値
 * @returns {string} - エラーメッセージ（エラーがない場合は空文字）
 */
export const validateInput = (input) => {
  // SQLインジェクションやXSS攻撃に使用される可能性のある文字をチェック
  const invalidChars = /[<>()'";\\/]/;
  if (invalidChars.test(input)) {
    return '不適切な文字が含まれています。';
  }
  return '';
};

// ============================================================
// 日付の妥当性チェック
// ============================================================
// 日付（範囲）
export const validateDateRange = (date) => {
  const minDate = new Date('1900-01-01');
  const maxDate = new Date('2999-12-31');
  const inputDate = new Date(date);

  if (inputDate < minDate || inputDate > maxDate) {
    return { message: '日付は1900年1月1日から2999年12月31日の間で設定してください。', error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 日付
export const validateDate = (date) => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateRegex.test(date)) {
    return { message: '日付の形式が正しくありません。YYYY-MM-DDの形式で入力してください。', error: true };
  }

  const [year, month, day] = date.split('-').map(Number);
  const inputDate = new Date(year, month - 1, day);

  if (isNaN(inputDate.getTime())) {
    return { message: '無効な日付です。', error: true };
  }

  if (inputDate.getFullYear() !== year || inputDate.getMonth() !== month - 1 || inputDate.getDate() !== day) {
    return { message: '存在しない日付です。', error: true };
  }
  const dateRangeResult = validateDateRange(date);
  if (dateRangeResult.error) {
    return dateRangeResult;
  }
  return { message: '', error: false };
};

// ============================================================
// ログイン
// ============================================================
// ログイン
export const validateLogin = (mailAddress, password) => {
  if (!mailAddress) {
    return { message: 'メールアドレスが入力されていません。', error: true };
  }
  if (!password) {
    return { message: 'パスワードが入力されていません。', error: true };
  }
  const specialCharactersRegex = /[!@#$%^&*(),.?":{}|<>]/;
  if (password.split('').some(char => !specialCharactersRegex.test(char) && !/[A-Za-z0-9]/.test(char))) {
    return { message: 'メールアドレスまたはパスワードが間違っています。', error: true };
  }
  return { message: '', error: false };
}

// ------------------------------------------------------------
// 現在のパスワード
export const validateCurrentPassword = (currentPassword) => {
  if (!currentPassword) {
    return { message: '現在のパスワードが入力されていません。', error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 新規パスワード、確認パスワード
export const validateNewAndConfirmPassword = (newPassword, confirmPassword) => {
  if (!newPassword) {
    return { message: '新しいパスワードが入力されていません。', error: true };
  }
  if (!confirmPassword) {
    return { message: '新しいパスワード（確認）が入力されていません。', error: true };
  }
  if (newPassword.length < 8 || newPassword.length > 16) {
    return { message: 'パスワードは8文字以上16文字以下で入力してください。', error: true };
  }
  if (!/^[A-Za-z0-9!@#$%^&*(),.?":{}|<>]+$/.test(newPassword)) {
    return { message: '使用できない文字が含まれています。英語、数字、記号のみ使用可能です。', error: true };
  }
  if (!/[0-9]/.test(newPassword)) {
    return { message: 'パスワードには少なくとも1つの数字を含めてください。', error: true };
  }
  if (!/[!@#$%^&*(),.?":{}|<>]/.test(newPassword)) {
    return { message: 'パスワードには少なくとも1つの記号を含めてください。', error: true };
  }
  if (!/[A-Z]/.test(newPassword)) {
    return { message: 'パスワードには少なくとも1つの英語大文字を含めてください。', error: true };
  }
  if (!/[a-z]/.test(newPassword)) {
    return { message: 'パスワードには少なくとも1つの英語小文字を含めてください。', error: true };
  }
  if (newPassword !== confirmPassword) {
    return { message: 'パスワードとパスワード（確認）が一致しません。', error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 現在のメールアドレス
export const validateCurrentMail = (currentMail) => {
  if (!currentMail) {
    return { message: '現在のメールアドレスが入力されていません。', error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 新しいメールアドレス
export const validateNewMail = (mail) => {
  if (!mail) {
    return { message: '新しいメールアドレスが入力されていません。', error: true };
  }
  if (mail.length > MaxLength.MAIL_MAX_LENGTH) {
    return { message: `新しいメールアドレスは${MaxLength.MAIL_MAX_LENGTH}文字以内で入力してください。`, error: true };
  }
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!emailRegex.test(mail)) {
    return { message: '有効なメールアドレスを入力してください。', error: true };
  }
  // ドメイン部分の妥当性チェック
  const domainRegex = /^[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;
  const domain = mail.split('@')[1];
  if (!domainRegex.test(domain)) {
    return { message: '新しいメールアドレスのドメイン部分が不正です。', error: true };
  }
  const invalidCharsRegex = /[\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FAF(),、。]/;
  if (invalidCharsRegex.test(mail)) {
    return { message: '新しいメールアドレスに使用できない文字が含まれています。', error: true };
  }
  const errorMessage = validateInput(mail);
  if (errorMessage) {
    return { message: errorMessage, error: true };
  }
  return { message: '', error: false };
};

// ============================================================
// 顧客マスタ
// ============================================================
// 会社名
export const validateCompanyName = (companyName) => {
  if (!companyName) {
    return { message: '会社名が入力されていません。', error: true };
  }
  if (companyName.length > MaxLength.COMPANY_NAME_MAX_LENGTH) {
    return { message: `会社名は${MaxLength.COMPANY_NAME_MAX_LENGTH}文字以内で入力してください。`, error: true };
  }
  if (/[\uff61-\uff9f]/.test(companyName)) {
    return { message: '会社名に半角カタカナを使用しないでください。', error: true };
  }
  if (companyName.match(/[^\u4e00-\u9faf\u3040-\u309f\u30a0-\u30ffA-Za-zＡ-Ｚａ-ｚ０-９ 0-9]/)) {
    return { message: '会社名に漢字、ひらがな、カタカナ、英数字以外の文字は使用しないでください。', error: true };
  }
  const errorMessage = validateInput(companyName);
  if (errorMessage) {
    return { message: errorMessage, error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 契約開始日
export const validateContractStartDate = (contractStartDate) => {
  if (!contractStartDate) {
    return { message: '契約開始日が入力されていません。', error: true };
  }
  const dateRangeResult = validateDateRange(contractStartDate);
  if (dateRangeResult.error) {
    return dateRangeResult;
  }
  contractStartDate = contractStartDate.replace(/-/g, '');
  if (contractStartDate.length !== 8) {
    return { message: '契約開始日は8桁で入力してください。', error: true };
  }
  if (!/^[0-9]+$/.test(contractStartDate)) {
    return { message: '契約開始日は半角数字のみで入力してください。', error: true };
  }
  const startDate = new Date(contractStartDate.substring(0, 4), contractStartDate.substring(4, 6) - 1, contractStartDate.substring(6, 8));
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  if (startDate < todayDate) {
    return { message: '契約開始日に過去の日付を設定できません。', error: true };
  }
  
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 契約終了日
export const validateContractEndDate = (contractEndDate) => {
  if (!contractEndDate) {
    return { message: '契約終了日が入力されていません。', error: true };
  }
  const dateRangeResult = validateDateRange(contractEndDate);
  if (dateRangeResult.error) {
    return dateRangeResult;
  }

  contractEndDate = contractEndDate.replace(/-/g, '');
  if (contractEndDate.length !== 8) {
    return { message: '契約終了日は8桁で入力してください。', error: true };
  }

  if (!/^[0-9]+$/.test(contractEndDate)) {
    return { message: '契約終了日は半角数字のみで入力してください。', error: true };
  }

  const endDate = new Date(contractEndDate.substring(0, 4), contractEndDate.substring(4, 6) - 1, contractEndDate.substring(6, 8));
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  
  if (endDate < todayDate) {
    return { message: '契約終了日に過去の日付を設定できません。', error: true };
  }
  
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 契約開始日、契約終了日
export const validateContractDate = (contractStartDate, contractEndDate) => {
  contractStartDate = contractStartDate.replace(/-/g, '');
  contractEndDate = contractEndDate.replace(/-/g, '');
  
  const startDate = new Date(contractStartDate.substring(0, 4), contractStartDate.substring(4, 6) - 1, contractStartDate.substring(6, 8));
  const endDate = new Date(contractEndDate.substring(0, 4), contractEndDate.substring(4, 6) - 1, contractEndDate.substring(6, 8));
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  
  if (startDate >= endDate) {
    return { message: '契約終了日には契約開始日よりも前の日付を設定できません。', error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 管理者アカウント数
export const validateEnterpriseAdminCount = (enterpriseAdminCount) => {
  if (!enterpriseAdminCount || enterpriseAdminCount < 1 || enterpriseAdminCount > 999) {
    return { message: '管理者アカウント数は1~999の数値を入力してください。', error: true };
  }
  const errorMessage = validateInput(enterpriseAdminCount);
  if (errorMessage) {
    return { message: errorMessage, error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// スタッフアカウント数
export const validateEnterpriseStaffCount = (enterpriseStaffCount) => {
  if (!enterpriseStaffCount || enterpriseStaffCount < 1 || enterpriseStaffCount > 999) {
    return { message: 'スタッフアカウント数は1~999の数値を入力してください。', error: true };
  }
  const errorMessage = validateInput(enterpriseStaffCount);
  if (errorMessage) {
    return { message: errorMessage, error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 郵便番号
export const validatePostalCode = (postalCode) => {
  if (!postalCode) {
    return { message: '郵便番号が入力されていません。', error: true };
  }
  if (!/^[0-9]+$/.test(postalCode) || postalCode.includes('-')) {
    return { message: '郵便番号は半角数字のみで入力してください。', error: true };
  }
  if (postalCode.length !== MaxLength.POSTAL_CODE_MAX_LENGTH) {
    return { message: `郵便番号は${MaxLength.POSTAL_CODE_MAX_LENGTH}桁で入力してください。`, error: true };
  }
  const errorMessage = validateInput(postalCode);
  if (errorMessage) {
    return { message: errorMessage, error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 住所1
export const validateAddress1 = (address1) => {
  if (!address1) {
    return { message: '住所1が入力されていません。', error: true };
  }
  if (address1.length > MaxLength.ADDRESS_MAX_LENGTH) {
    return { message: `住所1は${MaxLength.ADDRESS_MAX_LENGTH}文字以内で入力してください。`, error: true };
  }
  const errorMessage = validateInput(address1);
  if (errorMessage) {
    return { message: errorMessage, error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 住所2
export const validateAddress2 = (address2) => {
  if (address2.length > MaxLength.ADDRESS_MAX_LENGTH) {
    return { message: `住所2は${MaxLength.ADDRESS_MAX_LENGTH}文字以内で入力してください。`, error: true };
  }
  const errorMessage = validateInput(address2);
  if (errorMessage) {
    return { message: errorMessage, error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 備考
export const validateNote = (note) => {
  if (note.length > MaxLength.NOTE_MAX_LENGTH) {
    return { message: `備考は${MaxLength.NOTE_MAX_LENGTH}文字以内で入力してください。`, error: true };
  }
  if (note.match(/[^\u4e00-\u9faf\u3040-\u309f\u30a0-\u30ffA-Za-z0-9\s、。！？「」（）［］【】]/)) {
    return { message: '備考に使用できる文字は、ひらがな、カタカナ、漢字、アルファベット、数字、空白、記号（、。、！？、「」、（）、［］、【】）のみです。', error: true };
  }
  const errorMessage = validateInput(note);
  if (errorMessage) {
    return { message: errorMessage, error: true };
  }
  return { message: '', error: false };
};

// ============================================================
// 顧客マスタ（請求書）
// ============================================================
// 請求書番号
export const validateInvoiceNumber = (invoiceNumber) => {
  if (!invoiceNumber) {
    return { message: '請求書番号が入力されていません。', error: true };
  }
  if (!/^[A-Za-z0-9]+$/.test(invoiceNumber)) {
    return { message: '請求書番号は使用できる文字は、英数字のみです。', error: true };
  }
  if (invoiceNumber.length > MaxLength.INVOICE_NUMBER_MAX_LENGTH) {
    return { message: `請求書番号は${MaxLength.INVOICE_NUMBER_MAX_LENGTH}文字以内で入力してください。`, error: true };
  }
  const errorMessage = validateInput(invoiceNumber);
  if (errorMessage) {
    return { message: errorMessage, error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 請求書発行日
export const validateInvoiceDate = (invoiceDate) => {
  if (!invoiceDate) {
    return { message: '請求書発行日が入力されていません。', error: true };
  }
  const dateRangeResult = validateDate(invoiceDate);
  if (dateRangeResult.error) {
    return dateRangeResult;
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 支払期限日
export const validatePaymentDue = (paymentDue) => {
  const dateRangeResult = validateDate(paymentDue);
  if (dateRangeResult.error) {
    return dateRangeResult;
  }
  paymentDue = paymentDue.replace(/-/g, '');
  if (paymentDue.length !== 8) {
    return { message: '支払期限日は8桁で入力してください。', error: true };
  }
  if (!/^[0-9]+$/.test(paymentDue)) {
    return { message: '支払期限日は半角数字のみで入力してください。', error: true };
  }

  return { message: '', error: false };
};

// ------------------------------------------------------------
// 請求書発行日と支払期限日
export const validateInvoiceDateAndPaymentDueDate = (invoiceDate, paymentDue) => {
  if (paymentDue < invoiceDate) {
    return { message: '支払期限日は請求書発行日以降の日付を設定してください。', error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 請求明細
export const validateInvoiceDetail = (invoiceItems) => {
  if (!invoiceItems||invoiceItems.length === 0) {
    return { message: '請求明細が入力されていません。', error: true };
  }
  for (const item of invoiceItems) {
    if (!item.name || item.name.trim() === '') {
      return { message: '入力されていない品名があります。', error: true };
    }
    if (item.name.length > 20) {
      return { message: '品名は20文字以内で入力してください。', error: true };
    }
    if (!item.quantity || isNaN(item.quantity)) {
      return { message: '入力されていない数量があります。', error: true };
    }
    if (item.quantity < 1 || item.quantity > 99) {
      return { message: '数量は1~99の範囲で入力してください。', error: true };
    }
    if (!item.unitPrice || isNaN(item.unitPrice)) {
      return { message: '入力されていない単価があります。', error: true };
    }
    if (item.unitPrice < 1 || item.unitPrice > 9999999) {
      return { message: '単価は1~9,999,999の範囲で入力してください。', error: true };
    }
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 消費税
export const validateTax = (tax) => {
  if (!tax) {
    return { message: '消費税が入力されていません。', error: true };
  }
  if (tax < 0 || tax > 100) {
    return { message: '消費税は0~100の範囲で入力してください。', error: true };
  }
  return { message: '', error: false };
};

// ============================================================
// 管理者/スタッフマスタ
// ============================================================
// 管理者名/スタッフ名
// userType: 1:CLASSIX(未使用) 2:管理者 3:スタッフ
export const validateUserName = (userName, userType) => {
  let userNameText = '';
  switch (userType) {
    case USER_TYPE.OWNER:
      userNameText = 'CLASSIX';
      break;
    case USER_TYPE.ADMIN:
      userNameText = '管理者名';
      break;
    case USER_TYPE.STAFF:
      userNameText = 'スタッフ名';
      break;
    default:
      userNameText = 'スタッフ名';
      break;
  }
  
  if (!userName) {
    return { message: `${userNameText}が入力されていません。`, error: true };
  }
  if (userName.length > MaxLength.USER_NAME_MAX_LENGTH) {
    return { message: `${userNameText}は${MaxLength.USER_NAME_MAX_LENGTH}文字以内で入力してください。`, error: true };
  }
  if (/[\uff61-\uff9f]/.test(userName)) {
    return { message: `${userNameText}に半角カタカナを使用しないでください。`, error: true };
  }
  if (userName.match(/[^\u4e00-\u9faf\u3040-\u309f\u30a0-\u30ffA-Za-zＡ-Ｚａ-ｚ０-９ 0-9]/)) {
    return { message: `${userNameText}に漢字、ひらがな、カタカナ、アルファベット、数字以外の文字は使用しないでください。`, error: true };
  }
  const errorMessage = validateInput(userName);
  if (errorMessage) {
    return { message: errorMessage, error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 管理者名（かな）/スタッフ名（かな）
export const validateUserNameKana = (adminNameKana, userType) => {
  let userNameText = '';
  switch (userType) {
    case USER_TYPE.OWNER:
      userNameText = 'CLASSIX';
      break;
    case USER_TYPE.ADMIN:
      userNameText = '管理者名';
      break;
    case USER_TYPE.STAFF:
      userNameText = 'スタッフ名';
      break;
    default:
      userNameText = 'スタッフ名';
      break;
  }

  if (!adminNameKana) {
    return { message: `${userNameText}（かな）が入力されていません。`, error: true };
  }
  if (adminNameKana.length > MaxLength.USER_NAME_KANA_MAX_LENGTH) {
    return { message: `${userNameText}（かな）は${MaxLength.USER_NAME_KANA_MAX_LENGTH}文字以内で入力してください。`, error: true };
  }
  if (adminNameKana.match(/[^\u3040-\u309f\u30a0-\u30ffA-Za-zＡ-Ｚａ-ｚ０-９0-9]/)) {
    return { message: `${userNameText}（かな）にひらがな、アルファベット、数字以外の文字は使用しないでください。`, error: true };
  }
  const errorMessage = validateInput(adminNameKana);
  if (errorMessage) {
    return { message: errorMessage, error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// メールアドレス
export const validateMail = (mail) => {
  if (!mail) {
    return { message: 'メールアドレスが入力されていません。', error: true };
  }
  if (mail.length > MaxLength.MAIL_MAX_LENGTH) {
    return { message: `メールアドレスは${MaxLength.MAIL_MAX_LENGTH}文字以内で入力してください。`, error: true };
  }
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!emailRegex.test(mail)) {
    return { message: '有効なメールアドレスを入力してください。', error: true };
  }
  // ドメイン部分の妥当性チェック
  const domainRegex = /^[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;
  const domain = mail.split('@')[1];
  if (!domainRegex.test(domain)) {
    return { message: 'メールアドレスのドメイン部分が不正です。', error: true };
  }
  const invalidCharsRegex = /[\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FAF(),、。]/;
  if (invalidCharsRegex.test(mail)) {
    return { message: 'メールアドレスに使用できない文字が含まれています。', error: true };
  }
  const errorMessage = validateInput(mail);
  if (errorMessage) {
    return { message: errorMessage, error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 利用開始日
export const validateStartDate = (startDate) => {
  if (!startDate) {
    return { message: '利用開始日が入力されていません。', error: true };
  }
  const dateRangeResult = validateDate(startDate);
  if (dateRangeResult.error) {
    return dateRangeResult;
  }
  startDate = startDate.replace(/-/g, '');
  const useStartDate = new Date(startDate.substring(0, 4), startDate.substring(4, 6) - 1, startDate.substring(6, 8));
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  if (useStartDate < todayDate) {
    return { message: '利用開始日に過去の日付を設定できません。', error: true };
  }
  
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 利用終了日
export const validateEndDate = (endDate) => {
  if (!endDate) {
    return { message: '利用終了日が入力されていません。', error: true };
  }
  const dateRangeResult = validateDate(endDate);
  if (dateRangeResult.error) {
    return dateRangeResult;
  }
  endDate = endDate.replace(/-/g, '');
  const useEndDate = new Date(endDate.substring(0, 4), endDate.substring(4, 6) - 1, endDate.substring(6, 8));
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  
  if (useEndDate < todayDate) {
    return { message: '利用終了日に過去の日付を設定できません。', error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 利用開始日、利用終了日
export const validateUseDate = (startDate, endDate) => {
  startDate = startDate.replace(/-/g, '');
  endDate = endDate.replace(/-/g, '');
  
  const useStartDate = new Date(startDate.substring(0, 4), startDate.substring(4, 6) - 1, startDate.substring(6, 8));
  const useEndDate = new Date(endDate.substring(0, 4), endDate.substring(4, 6) - 1, endDate.substring(6, 8));
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  
  if (useStartDate >= useEndDate) {
    return { message: '利用終了日には利用開始日よりも前の日付を設定できません。', error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 業務内容
export const validateWorkName = (workName) => {
  if (workName.length > MaxLength.WORK_NAME_MAX_LENGTH) {
    return { message: `業務内容は${MaxLength.WORK_NAME_MAX_LENGTH}文字以内で入力してください。`, error: true };
  }
  const errorMessage = validateInput(workName);
  if (errorMessage) {
    return { message: errorMessage, error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 誕生日
export const validateBirthday = (birthday) => {
  const dateRangeResult = validateDateRange(birthday);
  if (dateRangeResult.error) {
    return dateRangeResult;
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 性別
export const validateSex = (sex) => {
  if (!sex) {
    return { message: '性別を選択してください。', error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 電話番号
export const validatePhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return { message: '電話番号が入力されていません。', error: true };
  }
  if (!/^[0-9]+$/.test(phoneNumber)) {
    return { message: '電話番号は半角数字のみで入力してください。', error: true };
  }
  if (phoneNumber.length !== 10 && phoneNumber.length !== 11) {
    return { message: '電話番号は10桁または11桁で入力してください。', error: true };
  }
  const errorMessage = validateInput(phoneNumber);
  if (errorMessage) {
    return { message: errorMessage, error: true };
  }
  return { message: '', error: false };
};

// ============================================================
// スタッフグループ
// ============================================================
// グループ名
export const validateGroupName = (groupName) => {
  if (!groupName||groupName?.length === 0) {
    return { message: 'グループ名が入力されていません。', error: true };
  }
  if (groupName.length > MaxLength.GROUP_NAME_MAX_LENGTH) {
    return { message: `グループ名は${MaxLength.GROUP_NAME_MAX_LENGTH}文字以内で入力してください。`, error: true };
  }
  return { message: '', error: false };
}

// ------------------------------------------------------------
// グループ名（かな）
export const validateGroupNameKana = (groupNameKana) => {
  if (!groupNameKana||groupNameKana?.length === 0) {
    return { message: 'グループ名（かな）が入力されていません。', error: true };
  }
  if (groupNameKana.length > MaxLength.GROUP_NAME_KANA_MAX_LENGTH) {
    return { message: `グループ名（かな）は${MaxLength.GROUP_NAME_KANA_MAX_LENGTH}文字以内で入力してください。`, error: true };
  }
  return { message: '', error: false };
}

// ============================================================
// 研修マスタ
// ============================================================
// 研修名
export const validateJobName = (jobName) => {
  if (!jobName||jobName.length === 0) {
    return { message: '研修名が入力されていません。', error: true };
  }
  if (jobName.length > MaxLength.JOB_NAME_MAX_LENGTH) {
    return { message: `研修名は${MaxLength.JOB_NAME_MAX_LENGTH}文字以内で入力してください。`, error: true };
  }
  if (/[\uff61-\uff9f]/.test(jobName)) {
    return { message: '研修名に半角カタカナを使用しないでください。', error: true };
  }
  if (jobName.match(/[^\u4e00-\u9faf\u3040-\u309f\u30a0-\u30ffA-Za-zＡ-Ｚａ-ｚ０-９ 0-9（）＆？！]/)) {
    return { message: '研修名に漢字、ひらがな、カタカナ、英数字、（）、＆、？、！以外の文字は使用しないでください。', error: true };
  }
  const errorMessage = validateInput(jobName);
  if (errorMessage) {
    return { message: errorMessage, error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// VimeoID
export const validateVimeoId = (vimeoId) => {
  if (!vimeoId || vimeoId.length === 0) {
    return { message: 'Vimeo動画IDが入力されていません。', error: true };
  }
  if (vimeoId.length > MaxLength.VIMEO_ID_MAX_LENGTH) {
    return { message: `Vimeo動画IDは${MaxLength.VIMEO_ID_MAX_LENGTH}文字以内で入力してください。`, error: true };
  }
  if (/[^0-9]/.test(vimeoId)) {
    return { message: '数字以外の文字は使用しないでください。', error: true };
  }
  const errorMessage = validateInput(vimeoId);
  if (errorMessage) {
    return { message: errorMessage, error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// PDFファイル
export const validatePDFFile = (pdfFile) => {
  if (!pdfFile) {
    return { message: 'PDFファイルが入力されていません。', error: true };
  }
  if (!pdfFile.name.toLowerCase().endsWith('.pdf')) {
    return { message: 'PDFファイルを選択してください。', error: true };
  }
  const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB
  if (pdfFile.size > MAX_FILE_SIZE) {
    return { message: 'ファイルサイズは50MB以下にしてください。', error: true };
  }
  if (pdfFile.length > MaxLength.FILE_NAME_MAX_LENGTH) {
    return { message: `PDFファイル名は${MaxLength.FILE_NAME_MAX_LENGTH}文字以内で入力してください。`, error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 公開開始日
export const validateReleaseStartDate = (startDate) => {
  if (!startDate) {
    return { message: '公開開始日が入力されていません。', error: true };
  }
  const dateRangeResult = validateDate(startDate);
  if (dateRangeResult.error) {
    return dateRangeResult;
  }
  startDate = startDate.replace(/-/g, '');
  const useStartDate = new Date(startDate.substring(0, 4), startDate.substring(4, 6) - 1, startDate.substring(6, 8));
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  if (useStartDate < todayDate) {
    return { message: '公開開始日に過去の日付を設定できません。', error: true };
  }
  
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 公開終了日
export const validateReleaseEndDate = (endDate) => {
  const dateRangeResult = validateDate(endDate);
  if (dateRangeResult.error) {
    return dateRangeResult;
  }
  endDate = endDate.replace(/-/g, '');
  const useEndDate = new Date(endDate.substring(0, 4), endDate.substring(4, 6) - 1, endDate.substring(6, 8));
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  
  if (useEndDate < todayDate) {
    return { message: '公開終了日に過去の日付を設定できません。', error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 公開開始日、公開終了日
export const validateReleaseDate = (startDate, endDate) => {
  startDate = startDate.replace(/-/g, '');
  endDate = endDate.replace(/-/g, '');
  
  const useStartDate = new Date(startDate.substring(0, 4), startDate.substring(4, 6) - 1, startDate.substring(6, 8));
  const useEndDate = new Date(endDate.substring(0, 4), endDate.substring(4, 6) - 1, endDate.substring(6, 8));
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  
  if (useStartDate >= useEndDate) {
    return { message: '公開終了日には公開開始日よりも前の日付を設定できません。', error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 公開顧客
export const validatePublishCompanyIds = (publishCompanyIds) => {
  if (!publishCompanyIds || publishCompanyIds.length === 0) {
    return { message: '限定公開の場合は、公開する顧客を選択してください。', error: true };
  }
  return { message: '', error: false };
};

// ============================================================
// 電子サインマスタ
// ============================================================
// テンプレートID
export const validateTemplateId = (templateId) => {
  if (!templateId || templateId.length === 0) {
    return { message: 'テンプレートIDが入力されていません。', error: true };
  }
  if (templateId.length > MaxLength.ESIGN_TEMPLATE_ID_MAX_LENGTH) {
    return { message: `テンプレートIDは${MaxLength.ESIGN_TEMPLATE_ID_MAX_LENGTH}文字以内で入力してください。`, error: true };
  }
  const errorMessage = validateInput(templateId);
  if (errorMessage) {
    return { message: errorMessage, error: true };
  }
  return { message: '', error: false };
}

// ------------------------------------------------------------
// テンプレート名
export const validateTemplateName = (templateName) => {
  if (!templateName||templateName.length === 0) {
    return { message: 'テンプレート名が入力されていません。', error: true };
  }
  if (templateName.length > MaxLength.ESIGN_TEMPLATE_NAME_MAX_LENGTH) {
    return { message: `テンプレート名は${MaxLength.ESIGN_TEMPLATE_NAME_MAX_LENGTH}文字以内で入力してください。`, error: true };
  }
  if (/[\uff61-\uff9f]/.test(templateName)) {
    return { message: 'テンプレート名に半角カタカナを使用しないでください。', error: true };
  }
  if (templateName.match(/[^\u4e00-\u9faf\u3040-\u309f\u30a0-\u30ffA-Za-zＡ-Ｚａ-ｚ０-９ 0-9（）＆？！]/)) {
    return { message: 'テンプレート名に漢字、ひらがな、カタカナ、英数字、（）、＆、？、！以外の文字は使用しないでください。', error: true };
  }
  const errorMessage = validateInput(templateName);
  if (errorMessage) {
    return { message: errorMessage, error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// テンプレート名（かな）
export const validateTemplateNameKana = (templateNameKana) => {
  if (!templateNameKana||templateNameKana.length === 0) {
    return { message: 'テンプレート名（かな）が入力されていません。', error: true };
  }
  if (templateNameKana.length > MaxLength.ESIGN_TEMPLATE_NAME_KANA_MAX_LENGTH) {
    return { message: `テンプレート名（かな）は${MaxLength.ESIGN_TEMPLATE_NAME_KANA_MAX_LENGTH}文字以内で入力してください。`, error: true };
  }
  if (/[\uff61-\uff9f]/.test(templateNameKana)) {
    return { message: 'テンプレート名（かな）に半角カタカナを使用しないでください。', error: true };
  }
  if (templateNameKana.match(/[^\u3040-\u309f\u30a0-\u30ffA-Za-zＡ-Ｚａ-ｚ０-９ 0-9（）＆？！]/)) {
    return { message: 'テンプレート名（かな）にひらがな、カタカナ、英数字、（）、＆、？、！以外の文字は使用しないでください。', error: true };
  }
  const errorMessage = validateInput(templateNameKana);
  if (errorMessage) {
    return { message: errorMessage, error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// テンプレート文書
export const validateTemplateDocument = (templateDocument) => {
  if (!templateDocument || templateDocument.length === 0) {
    return { message: 'テンプレート文書が入力されていません。', error: true };
  }
  if (templateDocument.length > MaxLength.ESIGN_DOCUMENT_MAX_COUNT) {
    return { message: `テンプレート文書は${MaxLength.ESIGN_DOCUMENT_MAX_COUNT}件までです。`, error: true };
  }
  const hasValidDocument = templateDocument.some(document => 
    document.documentName && document.documentName.length > 0);
  if (!hasValidDocument) {
    return { message: 'テンプレート文書が正しく入力されていません。', error: true };
  }
  for (let i = 0; i < templateDocument.length; i++) {
    const document = templateDocument[i];
    
    if (!document.documentName || document.documentName.length === 0) {
      return { message: `文書${i + 1}の名前が入力されていません。`, error: true };
    }

    if (document.pdfFile) {
      if (document.pdfFile.type !== 'application/pdf') {
        return { message: `文書${i + 1}：PDFファイルを選択してください。`, error: true };
      } 
      const fileNameWithoutExtension = document.pdfFile.name.slice(0, document.pdfFile.name.lastIndexOf('.'));
      if (fileNameWithoutExtension.length > MaxLength.FILE_NAME_MAX_LENGTH) {
        return { message: `文書${i + 1}：PDFファイル名は${MaxLength.FILE_NAME_MAX_LENGTH}文字以内にしてください。（拡張子を除く）`, error: true };
      }
      const MAX_FILE_SIZE = 50000000; // 50MB
      if (document.pdfFile.size > MAX_FILE_SIZE) {
        return { message: `文書${i + 1}：ファイルサイズは${MaxLength.FILE_NAME_MAX_SIZE_MB}MB以下にしてください。`, error: true };
      }
    }
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// テンプレート公開
export const validateTemplatePublishCompanyList = (companyList) => {
  if (!companyList || companyList.length === 0) {
    return { message: 'テンプレート公開先が入力されていません。', error: true };
  }
  return { message: '', error: false };
};

// ============================================================
// 通知マスタ
// ============================================================
// 通知タイトル
export const validateNotificationTitle = (title) => {
  if (!title||title.length === 0) {
    return { message: 'タイトルが入力されていません。', error: true };
  }
  if (title.length > MaxLength.NOTIFICATION_TITLE_MAX_LENGTH) {
    return { message: `タイトルは${MaxLength.NOTIFICATION_TITLE_MAX_LENGTH}文字以内で入力してください。`, error: true };
  }
  if (/[\uff61-\uff9f]/.test(title)) {
    return { message: 'タイトルに半角カタカナを使用しないでください。', error: true };
  }
  const errorMessage = validateInput(title);
  if (errorMessage) {
    return { message: errorMessage, error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 通知内容
export const validateNotificationContents = (contents) => {
  if (!contents||contents.length === 0) {
    return { message: '通知内容が入力されていません。', error: true };
  }
  if (contents.length > MaxLength.NOTIFICATION_CONTENTS_MAX_LENGTH) {
    return { message: `通知内容は${MaxLength.NOTIFICATION_CONTENTS_MAX_LENGTH}文字以内で入力してください。`, error: true };
  }
  if (/[\uff61-\uff9f]/.test(contents)) {
    return { message: '通知内容に半角カタカナを使用しないでください。', error: true };
  }
  const errorMessage = validateInput(contents);
  if (errorMessage) {
    return { message: errorMessage, error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 通知先
export const validateNotificationSendTo = (sendToIds, sendType) => {
  if (sendType === SEND_TO_TYPE.GROUP && (!sendToIds || sendToIds.length === 0)) {
    return { message: 'グループを選択してください。', error: true };
  }
  if (sendType === SEND_TO_TYPE.PERSONAL && (!sendToIds || sendToIds.length === 0)) {
    return { message: 'スタッフを選択してください。', error: true };
  }
  return { message: '', error: false };
};

// ============================================================
// アカウント設定
// ============================================================
// 適格請求書発行事業者登録番号
export const validateBusinessNumber = (businessNumber) => {
  if (!businessNumber) {
    return { message: '適格請求書発行事業者登録番号が入力されていません。', error: true };
  }
  if (!/^[0-9]+$/.test(businessNumber)) {
    return { message: '適格請求書発行事業者登録番号は半角数字のみで入力してください。', error: true };
  }
  if (businessNumber.length !== 13) {
    return { message: '適格請求書発行事業者登録番号は13桁で入力してください。', error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 振込先名
export const validatePayee = (payee) => {
  if (!payee||payee.length === 0) {
    return { message: '振込先が入力されていません。', error: true };
  } else if (payee.length > 100) {
    return { message: '振込先は100文字以内で入力してください。', error: true };
  }
  return { message: '', error: false };
};






// ------------------------------------------------------------
// 開始日、終了日（管理者）
export const validateStartAndEndDate = (startDate, endDate, isEdit = false) => {  
  const validStartDate = startDate.replace(/-/g, '');
  const validEndDate = endDate ? endDate.replace(/-/g, '') : null;
  
  if ((!isEdit) && validStartDate.length !== 8) {
    return { message: '利用開始日は8桁で入力してください。', error: true };
  }
  if (validEndDate && validEndDate.length !== 8) {
    return { message: '利用終了日は8桁で入力してください。', error: true };
  }
  if ((!isEdit) && !/^[0-9]+$/.test(validStartDate)) {
    return { message: '利用開始日は半角数字のみで入力してください。', error: true };
  }
  if (validEndDate && (!/^[0-9]+$/.test(validEndDate))) {
    return { message: '利用終了日は半角数字のみで入力してください。', error: true };
  }

  const formatStartDate = new Date(validStartDate.substring(0, 4), validStartDate.substring(4, 6) - 1, validStartDate.substring(6, 8));
  const formatEndDate = validEndDate ? new Date(validEndDate.substring(0, 4), validEndDate.substring(4, 6) - 1, validEndDate.substring(6, 8)) : null;
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  
  if ((!isEdit) && formatStartDate < todayDate) {
    return { message: '利用開始日に過去の日付を設定できません。', error: true };
  }
  if (formatEndDate && formatEndDate < todayDate) {
    return { message: '利用終了日に過去の日付を設定できません。', error: true };
  }
  if (formatStartDate && formatEndDate && formatStartDate >= formatEndDate) {
    return { message: '利用終了日には利用開始日よりも前の日付を設定できません。', error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// ファイルURL
export const validateVideoFile = (fileId) => {
  if (!fileId||fileId === '') {
    return { message: '動画のファイルIDが入力されていません。', error: true };
  }

  return { message: '', error: false };
};

export const validatePDFFileId = (fileId) => {
  if (!fileId || fileId === '') {
    return { message: 'PDFファイルが設定されていません。', error: true };
  }
  return { message: '', error: false };
};

export const validatePDFFileName = (fileName) => {
  if (!fileName || fileName === '') {
    return { message: 'PDFファイルが設定されていません。', error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 公開開始日、公開終了日、ステータス
export const validatePublishDate = (publishStartDate,publishEndDate,status) => {
  if(status === STATUS_TYPE.UNRELEASE){
    //未公開の場合のみチェック
    if (!publishStartDate) {
      return { message: '公開開始日が入力されていません。', error: true };
    }

    publishStartDate = publishStartDate.replace(/-/g, '');

    if (publishStartDate.length !== 8) {
      return { message: '公開開始日は8桁で入力してください。', error: true };
    }
    if (!/^[0-9]+$/.test(publishStartDate)) {
      return { message: '公開開始日は半角数字のみで入力してください。', error: true };
    }

    // 公開終了日が入力されている場合のみ
    if (publishEndDate) {
      
      publishEndDate = publishEndDate.replace(/-/g, '');

      if (publishEndDate.length !== 8) {
        return { message: '公開終了日は8桁で入力してください。', error: true };
      }
      
      if (!/^[0-9]+$/.test(publishEndDate)) {
        return { message: '公開終了日は半角数字のみで入力してください。', error: true };
      }
    
      const startDate = new Date(publishStartDate.substring(0, 4), publishStartDate.substring(4, 6) - 1, publishStartDate.substring(6, 8));
      const endDate = new Date(publishEndDate.substring(0, 4), publishEndDate.substring(4, 6) - 1, publishEndDate.substring(6, 8));
      const todayDate = new Date();
      todayDate.setHours(0, 0, 0, 0);
      
      if (startDate < todayDate) {
        return { message: '公開開始日に過去の日付を設定できません。', error: true };
      }
      if (endDate < todayDate) {
        return { message: '公開終了日に過去の日付を設定できません。', error: true };
      }
      if (startDate >= endDate) {
        return { message: '公開終了日には公開開始日よりも前の日付を設定できません。', error: true };
      }
    } 
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
// 公開範囲、グループID
export const validatePublishScope = (groupId, publishScope) => {
  if (publishScope === PUBLISH_SCOPE.LIMITED && !groupId) {
    return { message: '限定公開時は公開するグループを選択してください。', error: true };
  }
  return { message: '', error: false };
};

// ------------------------------------------------------------
/**@
 * テストのバリデーションチェック
 * @param {array} questions - テスト
 * @returns {object} - バリデーション結果
 */
export const validateQuestion = (questions) => {
  if (!questions || !Array.isArray(questions)) {
    return { message: 'テストが正しく設定されていません。', error: true };
  }

  for (let i = 0; i < questions.length; i++) {
    const question = questions[i];
  
    if (!question.questionText || question.questionText.trim() === '') {
      return { message: `問題文が不正です。（Q${i + 1}）`, error: true };
    }
    if (question.questionText.length > 60) {
      return { message: `問題文は60文字以内で入力してください。（Q${i + 1}）`, error: true };
    }

    if (!question.answerType || (question.answerType !== ANSWER_TYPE.SINGLE_CHOICE
      && question.answerType !== ANSWER_TYPE.MULTIPLE_CHOICE)) {
      return { message: `回答タイプが不正です。（Q${i + 1}）`, error: true };
    }

    if (!question.answerList || !Array.isArray(question.answerList) || question.answerList.length === 0) {
      return { message: `選択肢が正しく設定されていません。（Q${i + 1}）`, error: true };
    }
    let correctChoiceCount = 0;
    const choiceTexts = new Set();
  
    for (let j = 0; j < question.answerList.length; j++) {
      const choice = question.answerList[j];
      if (!choice.answerText || typeof choice.answerText !== 'string' || choice.answerText.trim() === '') {
        return { message: `選択肢が不正です。（Q${i + 1} 選択肢${j + 1}）`, error: true };
      }
      if (choice.answerText.length > 60) {
        return { message: `選択肢のテキストは60文字以内で入力してください。（Q${i + 1} 選択肢${j + 1}）`, error: true };
      }

      const choiceText = choice.answerText.trim();
      if (choiceTexts.has(choiceText)) {
        return { message: `選択肢が重複しています。（Q${i + 1} 選択肢${j + 1}）`, error: true };
      }
      choiceTexts.add(choiceText);
  
      if (choice.isCorrect) {
        correctChoiceCount++;
      }
    }

    if (correctChoiceCount < 1) {
      return { message: `正解を設定してください。（Q${i + 1}）`, error: true };
    }
    if (question.answerType === ANSWER_TYPE.SINGLE_CHOICE && correctChoiceCount !== 1) {
      return { message: `「単一選択式」では、複数の正解を設定できません。（Q${i + 1}）`, error: true };
    }
    if (question.answerType === ANSWER_TYPE.MULTIPLE_CHOICE && correctChoiceCount <= 1) {
      return { message: `「複数選択式」では、正しい選択肢が1つ以上必要です。（Q${i + 1}）`, error: true };
    }
  }
  return { message: '', error: false };
};

