import Cookies from 'js-cookie';
import { ErrorMessage } from '../const/Constant';
/**
 * エラーハンドリングを行う
 * @param {error} error Exception 
 */
export async function handleError(error) {
  if (handleError.called) {
    return;
  }
  //エラーハンドリングが呼ばれたことを記録
  handleError.called = true;
  
  const status = error?.response?.status;
  if (status) {

    switch (error.response.status) {
      case 400:
        alert(ErrorMessage.INVALID_REQUEST);
        break;
      
      case 401:
        // Session認証切れ
        alert(ErrorMessage.SESSION_EXPIRED);
        removeCookiesAndRedirect();
        break;
      
      case 404:
        alert(ErrorMessage.RESOURCE_NOT_FOUND);
        break;
      
      case 500:
        alert(ErrorMessage.SYSTEM_ERROR);
        break;
      
      default:
        alert(error.message);
        break;
    }
  } else {
    // ステータスコードがない場合の汎用エラーメッセージ
    alert(ErrorMessage.NON_STATUS_ERROR);
  }
}

function removeCookiesAndRedirect() {
  Cookies.remove('sessionId');
  Cookies.remove('role');
  Cookies.remove('userName');

  if (!Cookies.get('sessionId') && !Cookies.get('userId') && !Cookies.get('userName')) {
    window.location.href = '/';
  } else {
    setTimeout(removeCookiesAndRedirect, 100);
  }
}