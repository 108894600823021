/* ==================== */
/* 顧客追加モーダル */
/* ==================== */
import React,{ useEffect, useState } from 'react';
import { MaxLength } from '../../../const/Constant';
import { CONTRACT_STATUS, PLAN_TYPE } from '../../../const/Enum';
import { getAddress } from '../../../utils/addressUtil';
import { handleDateBlur as handleDateBlurUtil } from '../../../utils/dateInputUtil';
import { getCurrentDateString } from '../../../utils/dateUtils';
import { formatPostalCode } from '../../../utils/formatUtil';
import { StatusMessage } from '../../../components/StatusMessage/StatusMessage';
import * as validate from '../../../utils/realtimeValidation';
import './CustomerForm.css';

const CustomerForm = ({ formData, setFormData, isEdit}) => {
  const today = getCurrentDateString();
  const [showAddressButton, setShowAddressButton] = useState(false);
  
  /* エラーメッセージ */
  const [companyId, setCompanyId] = useState('');
  const [companyNameError, setCompanyNameError] = useState('');
  const [contractStartDateError, setContractStartDateError] = useState('');
  const [contractEndDateError, setContractEndDateError] = useState('');
  const [postalCodeError, setPostalCodeError] = useState('');
  const [address1Error, setAddress1Error] = useState('');
  const [address2Error, setAddress2Error] = useState('');
  const [enterpriseAdminCountError, setEnterpriseAdminCountError] = useState('');
  const [enterpriseStaffCountError, setEnterpriseStaffCountError] = useState('');
  const [noteError, setNoteError] = useState('');
  
  const isInactive = formData.contractStatus === CONTRACT_STATUS.INACTIVE ||
    ((formData.contractStatus === CONTRACT_STATUS.ACTIVE) && (new Date(formData.contractEndDate).getTime() === new Date(today).getTime()));
  
  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------ 
  useEffect(() => {
    if (formData.companyId !== companyId) { 
      setCompanyId(formData.companyId);
      setCompanyNameError('');
      setContractStartDateError('');
      setContractEndDateError('');
      setEnterpriseAdminCountError('');
      setEnterpriseStaffCountError('');
      setPostalCodeError('');
      setAddress1Error('');
      setAddress2Error('');
      setNoteError('');
    }
  }, [formData]);

  // ------------------------------------------------------------------------------------
  // イベント
  // ------------------------------------------------------------------------------------
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let newCompanyNameError = '';
    let newContractStartDateError = '';
    let newContractEndDateError = '';
    let newEnterpriseAdminCountError = '';
    let newEnterpriseStaffCountError = '';
    let newPostalCodeError = '';
    let newAddress1Error = '';
    let newAddress2Error = '';
    let newNoteError = '';

    if (name === 'companyName') {
      newCompanyNameError = validate.validateCompanyName(value);
    } else if (name === 'contractStartDate') {
      newContractStartDateError = validate.validateDateRangeCheck(value);
    } else if (name === 'contractEndDate') {
      newContractEndDateError = validate.validateDateRangeCheck(value);
    } else if (name === 'enterpriseAdminCount') {
      newEnterpriseAdminCountError = validate.validateEnterpriseAccountCount(value);
    } else if (name === 'enterpriseStaffCount') {
      newEnterpriseStaffCountError = validate.validateEnterpriseAccountCount(value);
    } else if (name === 'postalCode') {
      const formattedValue = formatPostalCode(value);
      newPostalCodeError = validate.validatePostalCode(formattedValue);
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: formattedValue
      }));
      setShowAddressButton(formattedValue.length === MaxLength.POSTAL_CODE_MAX_LENGTH + 1);
      return;
    } else if (name === 'address1') {
      newAddress1Error = validate.validateAddress(value, true);
    } else if (name === 'address2') {
      newAddress2Error = validate.validateAddress(value);
    } else if (name === 'note') {
      newNoteError = validate.validateNote(value);
    }

    // エラーステートを更新
    setCompanyNameError(prevCompanyNameError => name === 'companyName' ? newCompanyNameError : prevCompanyNameError);
    setContractStartDateError(prevContractStartDateError => name === 'contractStartDate' ? newContractStartDateError : prevContractStartDateError);
    setContractEndDateError(prevContractEndDateError => name === 'contractEndDate' ? newContractEndDateError : prevContractEndDateError);
    setEnterpriseAdminCountError(prevEnterpriseAdminCountError => name === 'enterpriseAdminCount' ? newEnterpriseAdminCountError : prevEnterpriseAdminCountError);
    setEnterpriseStaffCountError(prevEnterpriseStaffCountError => name === 'enterpriseStaffCount' ? newEnterpriseStaffCountError : prevEnterpriseStaffCountError);
    setPostalCodeError(prevPostalCodeError => name === 'postalCode' ? newPostalCodeError : prevPostalCodeError);
    setAddress1Error(prevAddress1Error => name === 'address1' ? newAddress1Error : prevAddress1Error);
    setAddress2Error(prevAddress2Error => name === 'address2' ? newAddress2Error : prevAddress2Error);
    setNoteError(prevNoteError => name === 'note' ? newNoteError : prevNoteError);

    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  }

  const handleDateBlur = (event) => {
    handleDateBlurUtil(event, setFormData);
  };

  // ------------------------------------------------------------------------------------
  // 住所取得
  // ------------------------------------------------------------------------------------
  const handleFetchAddress = async () => {
    if (!formData.postalCode) {
      return;
    }
    const address = await getAddress(formData.postalCode);
    if (address) {
      setFormData(prevFormData => ({
        ...prevFormData,
        address1: address.address1,
        address2: address.address2
      }));
    }
  }

  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------
  return (
    <div className="modal-form" id="customer-form">
      <StatusMessage
        status={formData.contractStatus}
        startDate={formData.contractStartDate}
        endDate={formData.contractEndDate}
        screen='customerMaster'
      />
      <p className="separate-title">基本情報</p>
      <div className="column-set" id="if-name">

        <div className="row-set">
          <div className="input-group">
            <label>会社名</label>
            <div className={`error-message-container ${companyNameError ? 'has-error' : ''}`}>
              <input
                type="text"
                name="companyName"
                value={formData.companyName}
                onChange={handleInputChange}
                placeholder="会社名"
                disabled={isEdit}
                style={{ borderColor: companyNameError ? 'red' : '' }}
              />
              {companyNameError && (
                <div className="error-message">
                  {companyNameError}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row-set">
          <div className="input-group">
            <label>契約開始日</label>
            <div className={`error-message-container ${contractStartDateError ? 'has-error' : ''}`}>
              <input
                type="date"
                name="contractStartDate"
                value={formData.contractStartDate}
                onChange={handleInputChange}
                onBlur={handleDateBlur}
                placeholder="契約開始日"
                pattern="\d{4}-\d{2}-\d{2}"
                min="1900-01-01"
                max="2999-12-31"
                disabled={isEdit}
                style={{ borderColor: contractStartDateError ? 'red' : '' }}
              />
              {contractStartDateError && (
                <div className="error-message">
                  {contractStartDateError}
                </div>
              )}
            </div>
          </div>
          <div className="input-group">
            <label>契約終了日</label>
            <div className={`error-message-container ${contractEndDateError ? 'has-error' : ''}`}>
              <input
                type="date"
                name="contractEndDate"
                value={formData.contractEndDate || ''}
                onChange={handleInputChange}
                onBlur={handleDateBlur}
                placeholder="契約終了日"
                pattern="\d{4}-\d{2}-\d{2}"
                min="1900-01-01"
                max="2999-12-31"
                disabled={isEdit && formData.contractStatus === CONTRACT_STATUS.INACTIVE}
                style={{ borderColor: contractEndDateError ? 'red' : '' }}
              />
              {contractEndDateError && (
                <div className="error-message">
                  {contractEndDateError}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="separate-line"></div>
      <p className="separate-title">プラン情報</p>

      <div className="column-set" id="if-name">
        <div className="row-set">
          <div className="input-group">
            <select
              name="planType"
              value={formData.planType}
              onChange={handleInputChange}
              disabled={isInactive}
            >
              <option value={PLAN_TYPE.DEMO}>Demo</option>
              <option value={PLAN_TYPE.LITE}>Lite</option>
              <option value={PLAN_TYPE.BASIC}>Standard</option>
              <option value={PLAN_TYPE.PREMIUM}>Premium</option>
              <option value={PLAN_TYPE.ENTERPRISE}>Enterprise</option>
            </select>
          </div>
        </div>

        {parseInt(formData.planType) === PLAN_TYPE.ENTERPRISE && (
          <div className="row-set">
            <div className="input-group">
              <label>管理者アカウント数</label>
              <div className={`error-message-container ${enterpriseAdminCountError ? 'has-error' : ''}`}>
                <input
                  type="number"
                  name="enterpriseAdminCount"
                  value={formData.enterpriseAdminCount}
                  onChange={handleInputChange}
                  min={1}
                  disabled={isInactive}
                  style={{ borderColor: enterpriseAdminCountError ? 'red' : '' }}
                />
                {enterpriseAdminCountError && (
                  <div className="error-message">
                    {enterpriseAdminCountError}
                  </div>
                )}
              </div>
            </div>

            <div className="input-group">
              <label>スタッフアカウント数</label>
              <div className={`error-message-container ${enterpriseStaffCountError ? 'has-error' : ''}`}>
                <input
                  type="number"
                  name="enterpriseStaffCount"
                  value={formData.enterpriseStaffCount}
                  onChange={handleInputChange}
                  min={1}
                  disabled={isInactive}
                  style={{ borderColor: enterpriseStaffCountError ? 'red' : '' }}
                />
                {enterpriseStaffCountError && (
                  <div className="error-message">
                    {enterpriseStaffCountError}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      
      <div className="separate-line"></div>
      <p className="separate-title">請求情報</p>

      <div className="column-set" >
        <div className="row-set postalcode-row" id="if-postalcode">
          <div className="input-group">
            <label>郵便番号</label>
            <div className={`error-message-container ${postalCodeError ? 'has-error' : ''}`}>
              <input
                type="text"
                name="postalCode"
                value={formData.postalCode}
                onChange={handleInputChange}
                placeholder="郵便番号"
                maxLength={MaxLength.POSTAL_CODE_MAX_LENGTH + 1}
                disabled={isEdit && formData.contractStatus === CONTRACT_STATUS.INACTIVE}
                style={{ borderColor: postalCodeError ? 'red' : '' }}
              />
              {postalCodeError && (
                <div className="error-message">
                  {postalCodeError}
                </div>
              )}
            </div>
            {formData.contractStatus !== CONTRACT_STATUS.INACTIVE && showAddressButton && (
              <button
                className="blue"
                onClick={handleFetchAddress}
              >
                <svg
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20px"
                  height="20px"
                  viewBox="0 0 24 24"
                  aria-labelledby="rotateIconTitle"
                  stroke="#007bff"
                  strokeWidth="2.4"
                  strokeLinecap="square"
                  strokeLinejoin="miter"
                  fill="none"
                  color="#007bff"
                >
                  <title id="rotateIconTitle">住所取得</title>
                  <path d="M22 12l-3 3-3-3" />
                  <path d="M2 12l3-3 3 3" />
                  <path d="M19.016 14v-1.95A7.05 7.05 0 0 0 8 6.22" />
                  <path d="M16.016 17.845A7.05 7.05 0 0 1 5 12.015V10" />
                  <path strokeLinecap="round" d="M5 10V9" />
                  <path strokeLinecap="round" d="M19 15v-1" />
                </svg>
                住所取得
              </button>
            )}
          </div>
        </div>

        <div className="row-set" id="if-address">
          <div className="input-group">
            <label>住所</label>
            <div className={`error-message-container ${address1Error ? 'has-error' : ''}`}>
              <input
                type="text"
                name="address1"
                value={formData.address1}
                onChange={handleInputChange}
                placeholder="住所1"
                disabled={isEdit && formData.contractStatus === CONTRACT_STATUS.INACTIVE}
                style={{ borderColor: address1Error ? 'red' : '' }}
              />
              {address1Error && (
                <div className="error-message">
                  {address1Error}
                </div>
              )}
            </div>
            <div className={`error-message-container ${address2Error ? 'has-error' : ''}`}>
              <input
                type="text"
                name="address2"
                value={formData.address2}
                onChange={handleInputChange}
                placeholder="住所2"
                disabled={isEdit && formData.contractStatus === CONTRACT_STATUS.INACTIVE}
                style={{ borderColor: address2Error ? 'red' : '' }}
              />
              {address2Error && (
                <div className="error-message">
                  {address2Error}
                </div>
              )}
            </div>
          </div>
        </div>
        
        <div className="row-set" id="if-note">
          <div className="input-group">
            <label>備考</label>
            <div className={`error-message-container ${noteError ? 'has-error' : ''}`}>
              <textarea
                name="note"
                value={formData.note}
                onChange={handleInputChange}
                placeholder="備考"
                disabled={isEdit && formData.contractStatus === CONTRACT_STATUS.INACTIVE}
                style={{
                  width: "100%",
                  borderColor: noteError ? 'red' : ''
                }}
              />
              {noteError && (
                <div className="error-message">
                  {noteError}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomerForm;