import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Menu from './components/Menu/Menu';
import { ErrorMessage } from './const/Constant';
import AccountSetting from './pages/AccountSetting/AccountSetting';
import CreateAccount from './pages/CreateAccount/CreateAccount';
import CustomerMaster from './pages/CustomerMaster/CustomerMaster';
import Login from './pages/Login/Login';
import Notification from './pages/Notification/Notification';
import TermsOfService from './pages/TermsOfService/TermsOfService';
import { UnreadCountProvider } from './context/UnreadCountContext';
import Maintenance from './pages/Maintenance/Maintenance';

import Reader from './pages/Reader';

import './global.css';
import './App.css';

const App = () => {
  // const [isMaintenanceMode, setMaintenanceMode] = useState(false);

  // const checkMaintenanceMode = async () => {
  //   try {
  //     const url = `${process.env.REACT_APP_BACKEND_URL}/api/checkMaintenance`;
  //     const response = await axios.post(url);
  //     if (response.data.isMaintenance) {
  //       setMaintenanceMode(true);
  //     }
  //   } catch (error) {
  //     //メンテナンスモード確認でエラーが発生した場合
  //     alert(ErrorMessage.OUT_OF_SERVICE);
  //   }
  // };
  
  // useEffect(() => {
  //   checkMaintenanceMode();
  // }, []);

  // if (isMaintenanceMode) {
  //   return <Maintenance />;
  // }
  
  return (
    <UnreadCountProvider>
      <Router>
        <Header />
        <AppContent />
      </Router>
    </UnreadCountProvider>
  );
};

const AppContent = () => {
  return (
    <div className="app-container">
      <Routes>
        {/* ログイン */}
        <Route path="/" element={
          <div className="content-container">
            <Login />
          </div>
        } />
        <Route path="/reader" element={
          <div className="content-container">
            <Reader />
          </div>
        } />
        {/* アカウント作成 */}
        <Route path="/createAccount" element={
          <div className="content-container">
            <CreateAccount />
          </div>
        } />
        {/* 顧客マスタ */}
        <Route path="/customer-master" element={
          <div className="full-container">
            <div className="menu-container">
              <Menu />
            </div>
            <div className="content-container">
              <CustomerMaster />
            </div>
          </div>
        } />
        {/* アカウント設定 */}
        <Route path="/account-setting" element={
          <div className="full-container">
            <div className="menu-container">
              <Menu />
            </div>
            <div className="content-container">
              <AccountSetting />
            </div>
          </div>
        } />
        {/* 通知画面 */}
        <Route path="/notification" element={
          <div className="full-container">
            <div className="menu-container">
              <Menu />
            </div>
            <div className="content-container">
              <Notification />
            </div>
          </div>
        } />
        {/* 利用規約 */}
        <Route path="/terms-of-service" element={
          <div className="full-container">
            <div className="menu-container">
              <Menu />
            </div>
            <div className="content-container">
              <TermsOfService />
            </div>
          </div>
        } />
      </Routes>
    </div>
  )
}

export default App;