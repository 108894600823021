/* ==================== */
/* アカウント設定画面 */
/* ==================== */
import React from 'react';
import './TermsOfService.css';

const TermsOfService = () => {
  

  // ------------------------------------------------------------------------------------
  // 一覧作成
  // ------------------------------------------------------------------------------------ 

  return (
    <div className="view-contents" id="terms-of-service-container">
      <div className="main-contents">
        <h2 className="page-title">利用規約</h2>

        <div className="scroll-form">
          <form className="terms-of-service-form">
            <p className="section-title">第1条（適用）</p>
            <p>本利用規約（以下「本規約」といいます。）は、[会社名]（以下「当社」といいます。）が提供するジョブルール動画システム（以下「本サービス」といいます。）の利用に関する条件を定めるものです。本規約は、本サービスを利用する全ての利用者（以下「利用者」といいます。）に適用されます。</p>
            <p className="section-title">第2条（定義）</p>
            <p>本サービス：当社が提供するジョブルール動画システムを指します。</p>
            <p>利用者：本規約に同意し、本サービスを利用する個人または法人を指します。</p>
            <p>コンテンツ：本サービスを通じて利用者がアップロード、共有、閲覧、ダウンロードする動画、画像、テキスト、その他のデータを指します。</p>
            <p className="section-title">第3条（利用登録）</p>
            <p>利用者は、本規約に同意の上、本サービスの利用登録を行うものとします。当社は、利用登録の申請者に対し、審査を行うことがあります。審査結果により、利用登録を拒否する場合があります。利用登録の際に提供された情報に虚偽、誤り、不足があった場合、当社は利用登録を取り消すことがあります。</p>
            <p className="section-title">第4条（利用者の責任）</p>
            <p>利用者は、自己の責任において本サービスを利用するものとし、本サービスを通じて行われる全ての行為およびその結果について責任を負うものとします。利用者は、他の利用者または第三者の権利を侵害することなく、本サービスを利用するものとします。</p>
            <p className="section-title">第5条（禁止事項）</p>
            <p>利用者は、本サービスの利用にあたり、以下の行為を行ってはなりません。</p>
            <ul>
              <li>法令または公序良俗に反する行為</li>
              <li>他の利用者または第三者の権利を侵害する行為</li>
              <li>虚偽の情報を提供する行為</li>
              <li>本サービスの運営を妨害する行為</li>
              <li>その他、当社が不適切と判断する行為</li>
            </ul>
            <p className="section-title">第6条（サービスの提供停止等）</p>
            <p>当社は、以下の場合には、事前の通知なく、本サービスの全部または一部の提供を停止または中断することがあります。</p>
            <ul>
              <li>本サービスにかかるシステムの保守、点検、更新を行う場合</li>
              <li>火災、停電、天災地変等の不可抗力により、本サービスの提供が困難となった場合</li>
              <li>その他、当社が本サービスの提供が困難と判断した場合</li>
            </ul>
            <p>当社は、本条に基づく本サービスの提供停止または中断により、利用者に生じた損害について、一切の責任を負わないものとします。</p>
            <p className="section-title">第7条（知的財産権）</p>
            <p>本サービスおよび本サービスに関連する一切の知的財産権は、当社または当社にその利用を許諾した権利者に帰属します。利用者は、当社の事前の許可なく、本サービスに関する情報を使用、複製、改変、第三者に提供することはできません。</p>
            <p className="section-title">第8条（利用者のコンテンツ）</p>
            <p>利用者は、自らが保有する正当な権利に基づき、コンテンツを本サービスにアップロードするものとします。利用者は、コンテンツが第三者の権利を侵害していないことを保証するものとします。当社は、利用者のコンテンツについて、一切の責任を負わないものとします。</p>
            <p className="section-title">第9条（契約の解除）</p>
            <p>利用者が本規約に違反した場合、当社は、利用者に対し、何らの通知を要することなく、本サービスの利用を停止し、または利用登録を解除することができます。当社は、本条に基づき利用登録を解除したことにより利用者に生じた損害について、一切の責任を負わないものとします。</p>
            <p className="section-title">第10条（免責事項）</p>
            <p>当社は、本サービスの提供に際して、利用者に生じた一切の損害について、当社の故意または重過失による場合を除き、一切の責任を負わないものとします。当社は、本サービスに関連して利用者間または利用者と第三者との間に生じたトラブルについて、一切の責任を負わないものとします。</p>
            <p className="section-title">第11条（利用規約の変更）</p>
            <p>当社は、必要と判断した場合には、本規約を変更することができます。本規約の変更は、当社が別途定める方法により通知し、通知後の利用者による本サービスの利用をもって、変更後の規約に同意したものとみなされます。</p>
            <p className="section-title">第12条（準拠法および管轄裁判所）</p>
            <p>本規約は、日本法に準拠し、解釈されるものとします。本サービスに関して生じた紛争については、[会社所在地]を管轄する裁判所を第一審の専属管轄裁判所とします。</p>
            <p>以上</p>
          </form>
        </div>
      </div>
    </div>
  )
}

export default TermsOfService;