/* ==================== */
/* アカウント設定画面 */
/* ==================== */
import React, { useEffect, useState, useRef } from 'react';
import Cookies from 'js-cookie';
import BottomSheet from '../../components/BottomSheet/BottomSheet';
import Loading from '../../components/Loading/Loading';
import MailChangeModal from '../../components/MailChangeModal/MailChangeModal';
import PasswordChangeModal from '../../components/PasswordChangeModal/PasswordChangeModal';
import useWindowSize from '../../hook/useWindowSize';
import { ErrorMessage, MaxLength, Message } from '../../const/Constant';
import { PLAN_TYPE, RESPONSE, USER_TYPE } from '../../const/Enum';
import { requestApiLoad, requestApiLoadAndBottom } from '../../utils/apiLoadUtil';
import { getAddress } from '../../utils/addressUtil';
import { formatPostalCode } from '../../utils/formatUtil';
import * as Validators from '../../utils/validation';
import * as validate from '../../utils/realtimeValidation';
import './AccountSetting.css';

const AccountSetting = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isMailModalOpen, setIsMailModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    companyName: '',
    businessNumber: '',
    postalCode: '',
    address1: '',
    address2: '',
    payee: ''
  });
  const [planData, setPlanData] = useState({
    planType: '',
    enterpriseAdminCount: '',
    enterpriseStaffCount: '',
  });
  const [showAddressButton, setShowAddressButton] = useState(true);
  const bottomSheetRef = useRef(null);
  const size = useWindowSize();

  /* エラーメッセージ */
  const [companyNameError, setCompanyNameError] = useState('');
  const [businessNumberError, setBusinessNumberError] = useState('');
  const [postalCodeError, setPostalCodeError] = useState('');
  const [address1Error, setAddress1Error] = useState('');
  const [address2Error, setAddress2Error] = useState('');
  const [payeeError, setPayeeError] = useState('');
  
  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      const res = await requestApiLoad('/client/get', {}, setIsLoading);
      if (res.return !== RESPONSE.SUCCESS) {
        return;
      }

      setFormData({
        companyName: res.companyName,
        businessNumber: 'T' + res.businessNumber,
        postalCode: formatPostalCode(res.postalCode),
        address1: res.address1,
        address2: res.address2,
        payee: res.payee,
      });

      if (Cookies.get('role') !== String(USER_TYPE.OWNER)) {
        const planRes = await requestApiLoad('/account/getPlan', {}, setIsLoading);
        if (planRes.return === RESPONSE.SUCCESS) {
          setPlanData({
            planType: planRes.planType,
            activeAdminCount: planRes.activeAdminCount,
            limitAdminCount: planRes.limitAdminCount,
            activeStaffCount: planRes.activeStaffCount,
            limitStaffCount: planRes.limitStaffCount,
          });
        }
      }
    };
    fetchData();
  }, []);

  // ------------------------------------------------------------------------------------
  // イベント
  // ------------------------------------------------------------------------------------
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    let newCompanyNameError = '';
    let newBusinessNumberError = '';
    let newPostalCodeError = '';
    let newAddress1Error = '';
    let newAddress2Error = '';
    let newPayeeError = '';
    
    let updatedFormData = { ...formData };

    if (name === 'companyName') {
      newCompanyNameError = validate.validateCompanyName(value);
      updatedFormData[name] = value;
    } else if (name === 'businessNumber') {
      let checkValue = value.replace(/[^0-9T]/g, '');
      if (checkValue.startsWith('T')) {
        checkValue = checkValue.slice(1);
      }
      if (checkValue.length > 0) {
        checkValue = 'T' + checkValue;
      }
      newBusinessNumberError = validate.validateBusinessNumber(checkValue.slice(1));
      updatedFormData[name] = checkValue;
    } else if (name === 'postalCode') {
      const formattedValue = formatPostalCode(value);
      newPostalCodeError = validate.validatePostalCode(formattedValue, true);
      updatedFormData[name] = formattedValue;
      setShowAddressButton(formattedValue.length === MaxLength.POSTAL_CODE_MAX_LENGTH + 1);
    } else if (name === 'address1' || name === 'address2') {
      if (name === 'address1') {
        newAddress1Error = validate.validateAddress(value, true);
      } else {
        newAddress2Error = validate.validateAddress(value);
      }
      updatedFormData[name] = value;
    } else if (name === 'payee') {
      newPayeeError = validate.validatePayee(value);
      updatedFormData[name] = value;
    }

    setFormData(updatedFormData);

    // エラーステートを更新
    setCompanyNameError(prevCompanyNameError => name === 'companyName' ? newCompanyNameError : prevCompanyNameError);
    setBusinessNumberError(prevBusinessNumberError => name === 'businessNumber' ? newBusinessNumberError : prevBusinessNumberError);
    setPostalCodeError(prevPostalCodeError => name === 'postalCode' ? newPostalCodeError : prevPostalCodeError);
    setAddress1Error(prevAddress1Error => name === 'address1' ? newAddress1Error : prevAddress1Error);
    setAddress2Error(prevAddress2Error => name === 'address2' ? newAddress2Error : prevAddress2Error);
    setPayeeError(prevPayeeError => name === 'payee' ? newPayeeError : prevPayeeError);
  }
  // ------------------------------------------------------------------------------------
  // アカウント情報更新
  // ------------------------------------------------------------------------------------
  const handleUpdate = async (event) => {
    event.preventDefault();
    const params = {
      companyName: formData.companyName,
      businessNumber: formData.businessNumber.replace(/^T/, ''),
      postalCode: formData.postalCode.replace(/-/g, ''),
      address1: formData.address1,
      address2: formData.address2,
      payee: formData.payee,
    };

    const validationResult = validateCheck(params);
    if (validationResult.error) {
      alert(validationResult.message);
      return;
    }

    const res = await requestApiLoadAndBottom('/client/update',
      params, setIsLoading, bottomSheetRef, Message.BS_UPDATE_COMPANY_SUCCESS);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }
  };
  
  // ------------------------------------------------------------------------------------
  // 受講履歴削除（DEMOプランのみ）
  // ------------------------------------------------------------------------------------
  const deleteResultData = async () => {
    if (window.confirm(`「${Cookies.get('userName')}」のすべての受講データを削除しますか？`)) {
      const res = await requestApiLoadAndBottom('/demo/resetResult',
        {}, setIsLoading, bottomSheetRef, Message.BS_RESET_RESULT_SUCCESS);
      
      if (res.return !== RESPONSE.SUCCESS) {
        alert(ErrorMessage.RESET_RESULT_FAILED);
        return;
      }
    } else {
      return;
    }
  };

  // ------------------------------------------------------------------------------------
  // 住所取得
  // ------------------------------------------------------------------------------------
  const handleFetchAddress = async () => {
    if (!formData.postalCode) {
      return;
    }
    const address = await getAddress(formData.postalCode);
    if (address) {
      setFormData(prevFormData => ({
        ...prevFormData,
        address1: address.address1,
        address2: address.address2
      }));
    }
  };

    // ------------------------------------------------------------------------------------
  // ValidationCheck
  // ------------------------------------------------------------------------------------
  const validateCheck = (params) => {
    // 会社名
    const companyNameResult = Validators.validateCompanyName(params.companyName);
    if (companyNameResult.error) {
      return { message: companyNameResult.message, error: true };
    }
    // 適格請求書発行事業者登録番号
    const businessNumberResult = Validators.validateBusinessNumber(params.businessNumber);
    if (businessNumberResult.error) {
      return { message: businessNumberResult.message, error: true };
    }
    // 郵便番号
    const postalCodeResult = Validators.validatePostalCode(params.postalCode);
    if (postalCodeResult.error) {
      return { message: postalCodeResult.message, error: true };
    }
    // 住所1
    const address1Result = Validators.validateAddress1(params.address1);
    if (address1Result.error) {
      return { message: address1Result.message, error: true };
    }
    // 住所2
    const address2Result = Validators.validateAddress2(params.address2);
    if (address2Result.error) {
      return { message: address2Result.message, error: true };
    }
    // 振込先名
    const payeeResult = Validators.validatePayee(params.payee);
    if (payeeResult.error) {
      return { message: payeeResult.message, error: true };
    }
    return { message: '', error: false };
  }

  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------
  return (
    <div className="view-contents" id="account-setting">
      <Loading isLoading={isLoading} />
      <BottomSheet ref={bottomSheetRef} />

      <div className="main-contents">
        {size.width > 1200 && (<h2 className="page-title">アカウント設定</h2>)}
        <div className="scroll-form">
          <div className="middle-contents">

            <div className="account-info-container">
              <p className="separate-title">アカウント</p>
              <div className="reset-password-button">
                <button
                  className='border-blue'
                  onClick={(e) => { e.preventDefault(); setIsPasswordModalOpen(true); }}
                  style={{
                    width: size.width > 768 ? '220px' : '100%',
                  }}>
                  パスワード変更
                </button>
                <button
                  className='border-blue'
                  onClick={(e) => { e.preventDefault(); setIsMailModalOpen(true); }}
                  style={{
                    width: size.width > 768 ? '220px' : '100%',
                  }}>
                  メールアドレス変更
                </button>
              </div>
            </div>
            
            {Cookies.get('role') === String(USER_TYPE.ADMIN) && (
              <div className="plan-info-container">
                <div className="separate-line"></div>
                <div className="spacer"></div>
                <p className="separate-title">プラン</p>
                <div className="plan-info-title">
                  <h3>
                    {
                      planData.planType === PLAN_TYPE.DEMO ? 'Demo' :
                        planData.planType === PLAN_TYPE.LITE ? 'Lite' :
                          planData.planType === PLAN_TYPE.BASIC ? 'Standard' :
                            planData.planType === PLAN_TYPE.PREMIUM ? 'Premium' :
                              planData.planType === PLAN_TYPE.ENTERPRISE ? 'Enterprise' : ''
                    }
                  </h3>
                  <span className="sub-text">
                    {
                      planData.planType === PLAN_TYPE.DEMO ? 'デモプラン' :
                        planData.planType === PLAN_TYPE.LITE ? 'ライトプラン' :
                          planData.planType === PLAN_TYPE.BASIC ? 'スタンダードプラン' :
                            planData.planType === PLAN_TYPE.PREMIUM ? 'プレミアムプラン' :
                              planData.planType === PLAN_TYPE.ENTERPRISE ? 'エンタープライズプラン' : ''
                    }
                  </span>
                  
                  
                </div>
                <div className="spacer30"></div>
                <div className="plan-info-list">
                  <div className='plan-info-item'>
                    <p>管理者</p>
                    <p>{planData.activeAdminCount} / {planData.limitAdminCount}</p>
                  </div>
                  <div className="plan-info-item">
                    <p>スタッフ</p>
                    <p>{planData.activeStaffCount} / {planData.limitStaffCount}</p>
                  </div>
                </div>

                <div className="spacer"></div>
              </div>
            )}
          
            {planData.planType === PLAN_TYPE.DEMO && (
              <div className="reset-result-container">
                <div className="separate-line"></div>
                <div className="spacer"></div>
                <p className="separate-title">デモオプション</p>
                <div className="reset-result-button">
                  <button
                    className="red"
                    onClick={(e) => { e.preventDefault(); deleteResultData(); }}
                  >
                    すべての受講データの削除
                  </button>
                </div>
                <div className="spacer"></div>
              </div>
            )}
          </div>

          {Cookies.get('role') === String(USER_TYPE.OWNER) && (
            <div className="billing-info-container">
              <div className="spacer30"></div>
              <div className="separate-line"></div>
              <p className="separate-title">請求情報</p>
              <div className="column-set" >

                <div className="row-set" id="if-name">
                  <div className="input-group">
                    <label>会社名</label>
                    <div className={`error-message-container ${companyNameError ? 'has-error' : ''}`}>
                      <input
                        type="text"
                        name="companyName"
                        value={formData.companyName}
                        onChange={handleInputChange}
                        placeholder="会社名"
                        style={{ borderColor: companyNameError ? 'red' : '' }}
                      />
                      {companyNameError && (
                        <div className="error-message">
                          {companyNameError}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row-set" id="if-name">
                  <div className="input-group">
                    <label>適格請求書発行事業者登録番号</label>
                    <div className={`error-message-container ${businessNumberError ? 'has-error' : ''}`}>
                      <input
                        type="text"
                        name="businessNumber"
                        value={formData.businessNumber}
                        onChange={handleInputChange}
                        placeholder="適格請求書発行事業者登録番号"
                        maxLength={MaxLength.BUSINESS_NUMBER_MAX_LENGTH + 2}
                        style={{ borderColor: businessNumberError ? 'red' : '' }}
                      />
                      {businessNumberError && (
                        <div className="error-message">
                          {businessNumberError}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row-set postalcode-row" id="if-postalcode">
                  <div className="input-group">
                    <label>郵便番号</label>
                    <div className={`error-message-container ${postalCodeError ? 'has-error' : ''}`}>
                      <input
                        type="text"
                        name="postalCode"
                        value={formData.postalCode}
                        onChange={handleInputChange}
                        placeholder="郵便番号"
                        maxLength={MaxLength.POSTAL_CODE_MAX_LENGTH + 1}
                        style={{ borderColor: postalCodeError ? 'red' : '' }}
                      />
                      {postalCodeError && (
                        <div className="error-message">
                          {postalCodeError}
                        </div>
                      )}
                    
                    </div>
                    {showAddressButton && (
                      <button
                        className="blue"
                        onClick={handleFetchAddress}
                      >
                        <svg
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20px"
                          height="20px"
                          viewBox="0 0 24 24"
                          aria-labelledby="rotateIconTitle"
                          stroke="#007bff"
                          strokeWidth="2.4"
                          strokeLinecap="square"
                          strokeLinejoin="miter"
                          fill="none"
                          color="#007bff"
                        >
                          <title id="rotateIconTitle">住所取得</title>
                          <path d="M22 12l-3 3-3-3" />
                          <path d="M2 12l3-3 3 3" />
                          <path d="M19.016 14v-1.95A7.05 7.05 0 0 0 8 6.22" />
                          <path d="M16.016 17.845A7.05 7.05 0 0 1 5 12.015V10" />
                          <path strokeLinecap="round" d="M5 10V9" />
                          <path strokeLinecap="round" d="M19 15v-1" />
                        </svg>
                        住所取得
                      </button>
                    )}
                  </div>
                </div>
              
                <div className="row-set" id="if-address">
                  <div className="input-group">
                    <label>住所</label>
                    <div className={`error-message-container ${address1Error ? 'has-error' : ''}`}>
                      <input
                        type="text"
                        name="address1"
                        value={formData.address1}
                        onChange={handleInputChange}
                        placeholder="住所1"
                        style={{ borderColor: address1Error ? 'red' : '' }}
                      />
                      {address1Error && (
                        <div className="error-message">
                          {address1Error}
                        </div>
                      )}
                    </div>

                    <div className={`error-message-container ${address2Error ? 'has-error' : ''}`}>
                      <input
                        type="text"
                        name="address2"
                        value={formData.address2}
                        onChange={handleInputChange}
                        placeholder="住所2"
                        style={{ borderColor: address2Error ? 'red' : '' }}
                      />
                      {address2Error && (
                        <div className="error-message">
                          {address2Error}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
         
                <div className="row-set" id="if-note">
                  <div className="input-group">
                    <label>振込先</label>
                    <div className={`error-message-container ${payeeError ? 'has-error' : ''}`}>
                      <textarea
                        name="payee"
                        value={formData.payee}
                        onChange={handleInputChange}
                        placeholder="振込先"
                        style={{
                          height: '100px',
                          width: '100%',
                          borderColor: payeeError ? 'red' : ''
                        }}
                      />
                      {payeeError && (
                        <div className="error-message">
                          {payeeError}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="billing-bottom-button">
                <button type="submit" className="blue no-right-space" onClick={handleUpdate}>
                  更新
                </button>
              </div>
              <div className="spacer"></div>
            </div>
          )}

          <PasswordChangeModal
            isOpen={isPasswordModalOpen}
            closeModal={() => setIsPasswordModalOpen(false)}
            bottomSheetRef={bottomSheetRef}
            setIsLoading={setIsLoading}
          />

          <MailChangeModal
            isOpen={isMailModalOpen}
            closeModal={() => setIsMailModalOpen(false)}
            bottomSheetRef={bottomSheetRef}
            setIsLoading={setIsLoading}
          />
        </div>
      </div>
    </div>
  )
}

export default AccountSetting;