/* ==================== */
/* 顧客マスタ画面 */
/* ==================== */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import BottomSheet from '../../components/BottomSheet/BottomSheet';
import CustomerForm from '../../components/Form/CustomerForm/CustomerForm';
import CustomerPaymentForm from '../../components/Form/CustomerPaymentForm/CustomerPaymentForm';
import FilterButton from '../../components/FilterButton/FilterButton';
import InvoicePreview from '../../components/PDFLayout/Invoice/Invoice';
import Loading from '../../components/Loading/Loading';
import Modal from '../../components/Modal/Modal';
import Pagination from '../../components/Pagination/Pagination';
import SortingButton from '../../components/SortingButton/SortingButton';
import useWindowSize from '../../hook/useWindowSize';
import { CONTRACT_STATUS,PLAN_TYPE, RESPONSE } from '../../const/Enum';
import { MaxLength, Message } from '../../const/Constant';
import { formatPostalCode } from '../../utils/formatUtil';
import { getNextYearDateString, getCurrentDateString } from '../../utils/dateUtils';
import { requestApiLoad, requestApiLoadAndBottom } from '../../utils/apiLoadUtil';
import * as Validators from '../../utils/validation';
import './CustomerMaster.css';

const CustomerMaster = () => {
  const [itemList, setItemList] = useState([]);
  const [addItem, setAddItem] = useState([]);
  const [editItem, setEditItem] = useState([]);
  const [paymentItem, setPaymentItem] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [isInit, setIsInit] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const bottomSheetRef = useRef(null);
  const size = useWindowSize();
  const nextYearToday = getNextYearDateString();
  const today = getCurrentDateString();

  /* メイン一覧画面 */
  const [mainSearchTerm, setMainSearchTerm] = useState('');
  const [mainStatusFilter, setMainStatusFilter] = useState(0);
  const [mainSortConfig, setMainSortConfig] = useState({ key: 'companyId', direction: true });
  const [mainCurrentPage, setMainCurrentPage] = useState(1);
  const [mainLastPage, setMainLastPage] = useState(1);
  const [mainTotalCount, setMainTotalCount] = useState(0);
  const sortOptions = [
    { key: 'companyId', value: { key: 'companyId', direction: true }, label: '標準' },
    { key: 'contractStartDate_asc', value: { key: 'contractStartDate', direction: false }, label: '開始日（昇順）' },
    { key: 'contractStartDate_desc', value: { key: 'contractStartDate', direction: true }, label: '開始日（降順）' },
    { key: 'contractEndDate_asc', value: { key: 'contractEndDate', direction: false }, label: '終了日（昇順）' },
    { key: 'contractEndDate_desc', value: { key: 'contractEndDate', direction: true }, label: '終了日（降順）' }
  ];
  const statusOptions = [
    { value: CONTRACT_STATUS.ALL, label: 'すべて' },
    { value: CONTRACT_STATUS.ACTIVE, label: '契約中' },
    { value: CONTRACT_STATUS.PREPARING, label: '契約予定' },
    { value: CONTRACT_STATUS.INACTIVE, label: '契約終了' }
  ];

  /* 画面状態 */
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showPayment, setShowPayment] = useState(false);

  // ------------------------------------------------------------------------------------
  // 初期処理
  // ------------------------------------------------------------------------------------
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const params = {
  //       page: 1,
  //       isDesc: mainSortConfig.direction,
  //       orderKey: mainSortConfig.key,
  //       contractType: mainStatusFilter,
  //       name: mainSearchTerm,
  //     }
  //     const res = await requestApiLoad('/company/get', params, setIsLoading);
  //     if (res.return !== RESPONSE.SUCCESS) {
  //       return;
  //     }
      
  //     setItemList(res.companyList);
  //     setMainCurrentPage(1);
  //     setMainLastPage(res.lastPage);
  //     setMainTotalCount(res.dataCount);
  //   };
  //   fetchData();
  // }, [mainStatusFilter, mainSortConfig.direction, mainSortConfig.key, mainSearchTerm]);

  // useEffect(() => {
  //   if(!isInit) {
  //     return;
  //   }
  //   if (itemList.length > 0) {
  //     if (size.width > 1200) {
  //       handleEdit(null, itemList[0]);
  //     }
  //     setIsInit(false);
  //   }
  //   // eslint-disable-next-line
  // }, [itemList]);

  // useEffect(() => {
  //   createEditTable();
  //   // eslint-disable-next-line
  // }, [editItem]);

  // ------------------------------------------------------------------------------------
  // 画面モード
  // ------------------------------------------------------------------------------------
  const MODE = {
    NONE: 'NONE',
    ADD: 'ADD',
    EDIT: 'EDIT',
    PAYMENT: 'PAYMENT',
  }

  const ChangeScreenMode = (mode) => {
    switch (mode) {
      case MODE.ADD:
        setShowAdd(true);
        setShowEdit(false);
        setShowPayment(false);
        break;
      
      case MODE.EDIT:
        setShowAdd(false);
        setShowEdit(true);
        setShowPayment(false);
        break;
      
      case MODE.PAYMENT:
        setShowAdd(false);
        setShowEdit(false);
        setShowPayment(true);
        break;
      
      case MODE.NONE:
      default:
        setShowAdd(false);
        setShowEdit(false);
        setShowPayment(false);
        break;
    }
  }

  // ------------------------------------------------------------------------------------
  // ページネーション
  // ------------------------------------------------------------------------------------
  const handleMainPageChange = async (pageNumber) => {
    setMainCurrentPage(pageNumber);
    
    const params = {
      page: pageNumber,
      isDesc: mainSortConfig.direction,
      orderKey: mainSortConfig.key,
      contractType: mainStatusFilter,
      name: mainSearchTerm,
    }
    
    const res = await requestApiLoad('/company/get', params, setIsLoading);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }

    setItemList(res.companyList);
    setMainLastPage(res.lastPage);
    setMainTotalCount(res.dataCount);
  };

  // ------------------------------------------------------------------------------------
  // フィルター
  // ------------------------------------------------------------------------------------
  const handleMainStatusChange = async (event) => {
    const newStatusFilter = Number(event.target.value);
    setMainCurrentPage(1);
    setMainStatusFilter(newStatusFilter);
  };

  const sortedAndFilteredItems = useMemo(() => {
    let filteredItems = itemList || [];
    return filteredItems;
  }, [itemList]);

  // ------------------------------------------------------------------------------------
  // ソート
  // ------------------------------------------------------------------------------------
  const handleMainSortChange = async (event) => {
    const newSortConfig = !event  ? { key: 'companyId', direction: true } : JSON.parse(event);
    setMainSortConfig(newSortConfig);
  };
  
  // ------------------------------------------------------------------------------------
  // 検索
  // ------------------------------------------------------------------------------------
  const onMainClear = () => {
    setMainSearchTerm('');
  };

  // ------------------------------------------------------------------------------------
  // 新規追加
  // ------------------------------------------------------------------------------------
  const handleAdd = async () => {
    setAddItem({
      companyId: '',
      companyName: '',
      userList: [
        {
          userName: '',
          userNameKana: '',
          mail: '',
        },
      ],
      postalCode: '',
      address1: '',
      address2: '',
      contractStartDate: today,
      contractEndDate: nextYearToday,
      note: '',
      planType: PLAN_TYPE.LITE,
      enterpriseAdminCount: MaxLength.PLAN_PREMIUM_MAX_ADMIN_COUNT,
      enterpriseStaffCount: MaxLength.PLAN_PREMIUM_MAX_STAFF_COUNT,
    });
    ChangeScreenMode(MODE.ADD);
  };

  const handleAddSubmit = async (event) => {
    event.preventDefault();

    const params = {
      companyName: addItem.companyName,
      postalCode: addItem.postalCode.replace(/-/g, ''),
      address1: addItem.address1,
      address2: addItem.address2,
      contractStartDate: addItem.contractStartDate,
      contractEndDate: addItem.contractEndDate,
      note: addItem.note,
      planType: parseInt(addItem.planType),
      enterpriseAdminCount: parseInt(addItem.enterpriseAdminCount),
      enterpriseStaffCount: parseInt(addItem.enterpriseStaffCount),
    }

    const validationResult = validateCompanyData(params);
    if (validationResult.error) {
      alert(validationResult.message);
      return;
    }

    let isContinue = true;
    if (addItem.contractStartDate === today) {
      if (!window.confirm(Message.CONFIRM_NOW_ADD_CUSTOMER)) {
        isContinue = false;
      }
    } else {
      if (!window.confirm(Message.CONFIRM_SCHEDULE_ADD_CUSTOMER)) {
        isContinue = false;
      }
    }

    if (isContinue) {
      const res = await requestApiLoadAndBottom('/company/add',
        params, setIsLoading, bottomSheetRef, Message.BS_ADD_CUSTOMER_SUCCESS);
      if (res.return !== RESPONSE.SUCCESS) {
        return;
      }
      setFirstItem(res);

      if (size.width <= 1200) {
        ChangeScreenMode(MODE.NONE);
      } else {
        ChangeScreenMode(MODE.EDIT);  
      }
    }
  };

  // ------------------------------------------------------------------------------------
  // 編集
  // ------------------------------------------------------------------------------------
  const handleEdit = async (e, item) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    if (!item) { return; }
    setSelectedItem(item);

     // TODO : editItemいる？？selectedItemで良い気がする
    setEditItem({
      companyId: item.companyId,
      companyName: item.companyName,
      postalCode: formatPostalCode(item.postalCode),
      address1: item.address1,
      address2: item.address2,
      contractStatus: item.contractStatus,
      contractStartDate: item.contractStartDate,
      contractEndDate: item.contractEndDate,
      note: item.note,
      planType: item.planType,
      enterpriseAdminCount: item.enterpriseAdminCount || 6,
      enterpriseStaffCount: item.enterpriseStaffCount || 300,
    });
    ChangeScreenMode(MODE.EDIT);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();

    const params = {
      companyId: editItem.companyId,
      companyName: editItem.companyName,
      userList: editItem.userList,
      postalCode: editItem.postalCode.replace(/-/g, ''),
      address1: editItem.address1,
      address2: editItem.address2,
      contractStatus: editItem.contractStatus,
      contractStartDate: editItem.contractStartDate,
      contractEndDate: editItem.contractEndDate,
      note: editItem.note,
      planType: Number(editItem.planType),
      enterpriseAdminCount: Number(editItem.enterpriseAdminCount),
      enterpriseStaffCount: Number(editItem.enterpriseStaffCount),
    }
    
    const validationResult = validateCompanyData(params, true);
    if (validationResult.error) {
      alert(validationResult.message);
      return;
    }
    
    let isContinue = true;
    if (editItem.contractStartDate === today) {
      if (!window.confirm(Message.CONFIRM_NOW_ADD_CUSTOMER)) {
        isContinue = false;
      }
    } else {
      if (!window.confirm(Message.CONFIRM_SCHEDULE_ADD_CUSTOMER)) {
        isContinue = false;
      }
    }
    
    if (isContinue) {
      const res = await requestApiLoadAndBottom(`/company/update/${params.companyId}`,
        params, setIsLoading, bottomSheetRef, Message.BS_UPDATE_CUSTOMER_SUCCESS);
      if (res.return !== RESPONSE.SUCCESS) {
        if (res.errorCode === 'W-CO3-012') {
          alert('現在アクティブな管理者数以下の管理者アカウント数を設定できません。管理者数を減らす必要があります。');
          return;
        }
        if (res.errorCode === 'W-CO3-013') {
          alert('現在アクティブなスタッフ数以下のスタッフアカウント数を設定できません。スタッフ数を減らす必要があります。');
          return;
        }
        return;
      }
      setFirstItem(res);
      
      if (size.width <= 1200) {
        ChangeScreenMode(MODE.NONE);
      } else {
        ChangeScreenMode(MODE.EDIT);  
      }
    }
  }

  const handleStopSubmit = async (event) => {
    if (!window.confirm(Message.CONFIRM_STOP_CONTRACT)) { return; }
    event.preventDefault();

    const res = await requestApiLoadAndBottom(`/company/stop/${editItem.companyId}`,
      {}, setIsLoading, bottomSheetRef, Message.BS_STOP_CUSTOMER_SUCCESS);
    
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }
    setFirstItem(res);

    if (size.width <= 1200) {
      ChangeScreenMode(MODE.NONE);
    } else {
      ChangeScreenMode(MODE.EDIT);
    }
  }

  // ------------------------------------------------------------------------------------
  // 請求書出力
  // ------------------------------------------------------------------------------------
  const handlePayment = async (e, item) => {
    e.stopPropagation();

    if (!item) { return; }
    setSelectedItem(item);
    
    const res = await requestApiLoad(`/client/get`, {}, setIsLoading);
    if (res.return !== RESPONSE.SUCCESS) {
      return;
    }

    const client = res;
    if (!client.companyName ||
      !client.postalCode ||
      !client.address1 ||
      !client.payee ||
      !client.businessNumber
    ) {
      alert('請求書に必要な情報が取得できませんでした。リロードしてください。');
      return;
    }

    setPaymentItem({
      invoiceNumber: '', //請求書番号
      businessNumber: client?.businessNumber, //適格請求書発行事業者登録番号
      date: today,
      paymentDue: '',
      companyName: client?.companyName,
      companyAddress: '〒' + client?.postalCode.slice(0, 3) + '-' + client?.postalCode.slice(3) + ' ' + client?.address1 + client?.address2,
      customerName: item?.companyName,
      customerAddress: '〒' + item?.postalCode.slice(0, 3) + '-' + item?.postalCode.slice(3) + ' ' + item?.address1 + item?.address2,
      bankAccount: client?.payee || '',
      items: [{ name: '', quantity: '', unitPrice: '', amount: '' }],
      subtotal: 0,
      tax: 10,
      taxAmount: 0,
      totalAmount: 0,
      totalAmountWithTax: 0,
      note: '',
    });
    ChangeScreenMode(MODE.PAYMENT);
  }

  const handlePaymentSubmit = async (event) => {
    event.preventDefault();

    // バリデーションチェック
    if (validateInvoiceData(paymentItem).error) {
      alert(validateInvoiceData(paymentItem).message);
      return;
    }

    const invoiceDate = paymentItem.date ? paymentItem.date.replace(/(\d{4})-(\d{2})-(\d{2})/, '$1年$2月$3日') : '';
    const paymentDueDate = paymentItem.paymentDue ? paymentItem.paymentDue.replace(/(\d{4})-(\d{2})-(\d{2})/, '$1年$2月$3日') : '';
    const totalAmount = paymentItem.items.reduce((acc, item) => acc + (item.unitPrice * item.quantity), 0);
    const totalAmountWithTax = Math.floor(totalAmount * (1 + paymentItem.tax / 100));
    const taxAmount = Math.floor(totalAmount * (paymentItem.tax / 100));
    const updatedPaymentFormData = {
      ...paymentItem,
      taxAmount,
      totalAmount,
      totalAmountWithTax,
      date: invoiceDate,
      paymentDue: paymentDueDate,
    };
    
    const invoiceData = {
      invoiceNumber: updatedPaymentFormData.invoiceNumber,
      businessNumber: updatedPaymentFormData.businessNumber,
      date: updatedPaymentFormData.date,
      paymentDue: updatedPaymentFormData.paymentDue,
      companyName: updatedPaymentFormData.companyName,
      companyAddress: updatedPaymentFormData.companyAddress,
      customerName: updatedPaymentFormData.customerName,
      customerAddress: updatedPaymentFormData.customerAddress,
      bankAccount: updatedPaymentFormData.bankAccount,
      items: updatedPaymentFormData.items,
      tax: updatedPaymentFormData.tax,
      taxAmount: updatedPaymentFormData.taxAmount,
      totalAmount: updatedPaymentFormData.totalAmount,
      totalAmountWithTax: updatedPaymentFormData.totalAmountWithTax,
      note: updatedPaymentFormData.note
    };

    if (!window.confirm('顧客一覧のPDF出力をしますか？')) {
      return;
    }

    const element = document.createElement('div');
    document.body.appendChild(element);
    const root = ReactDOM.createRoot(element);
    root.render(<InvoicePreview data={invoiceData} />);
    await new Promise(resolve => setTimeout(resolve, 1000));

    const isMobile = window.innerWidth <= 480;
    const scale = isMobile ? 2 : 3.78;

    const canvas = await html2canvas(element, {
      width: 210 * scale,
      height: 297 * scale,
      scrollX: 0,
      scrollY: -window.scrollY
    });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    pdf.addImage(imgData, "PNG", 0, 0, 210, 297);
    const pdfData = pdf.output("blob");
    const pdfUrl = URL.createObjectURL(pdfData);
    window.open(pdfUrl, '_blank');
    root.unmount();
    document.body.removeChild(element);
  }

   // ------------------------------------------------------------------------------------
  // その他
  // ------------------------------------------------------------------------------------ 
  const getStatusText = (item) => {
    switch (item.contractStatus) {
      case CONTRACT_STATUS.ACTIVE :
        return ' (契約中)';
      
      case CONTRACT_STATUS.PREPARING:
        return ' (契約予定)';
      
      default:
        return ' (契約終了)';
    }
  }

  //　更新後、削除後に一覧の最初のデータを選択する処理
  const setFirstItem = (res) => {
    setItemList(res.companyList);
    setMainLastPage(res.lastPage);
    setMainTotalCount(res.dataCount);
      
    setEditItem(res.companyList[0]);
    setSelectedItem(res.companyList[0]);
    setMainCurrentPage(1);

    setMainStatusFilter(Number(CONTRACT_STATUS.ALL));
    setMainSearchTerm('');
    setMainSortConfig({ key: 'companyId', direction: true });
    resetScroll();
    resetScrollMain();
  }

  const resetScroll = () => {
    const scrollForm = document.querySelector('.scroll-form');
    if (scrollForm) {
      scrollForm.scrollTop = 0;
    }
  }

  const resetScrollMain = () => {
    const scrollForm = document.querySelector('.card-list');
    if (scrollForm) {
      scrollForm.scrollTop = 0;
    }
  }
  // ------------------------------------------------------------------------------------
  // 一覧作成
  // ------------------------------------------------------------------------------------ 
  const createTable = (itemList) => {
    return (
      <div className="card-list">
        {itemList.length === 0 ? (
          <div className="no-data">データがありません</div>
        ) : (
          itemList.map((item) => (
            <div className="card" key={item.companyId} onClick={(e) => handleEdit(e, item)}>
              <div className="card-contents" id="customer-master-card-contents">
                <div className="card-contents-left">
                  <h3>{item.companyName}</h3>
                  <span className="sub-text">{item.contractStartDate} ~ {item.contractEndDate}
                    {getStatusText(item)}</span>
                </div>
                <div className="card-footer">
                  {/* 詳細 */}
                  <button className="blue" onClick={(e) => handlePayment(e, item)} title="請求書">
                    <svg
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      aria-labelledby="fileIconTitle"
                      stroke="var(--blue-font-color)"
                      strokeWidth="1.8"
                      strokeLinecap="square"
                      strokeLinejoin="miter"
                      fill="none"
                      color="var(--blue-font-color)">
                      <title id="fileIconTitle">請求書</title>
                      <path strokeLinecap="round" d="M13 3v6" />
                      <path d="M13 3l6 6v12H5V3z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    );
  }

  // ------------------------------------------------------------------------------------
  // AddTable
  // ------------------------------------------------------------------------------------ 
  const createAddTable = () => {
    return (
      <>
        <div className="scroll-form">
          <CustomerForm
            formData={addItem}
            setFormData={setAddItem}
            isEdit={false}
          />
        </div>
        <div className="bottom-button">
          <button className="blue" onClick={handleAddSubmit}>
            登録
          </button>
        </div>
      </>
    )
  }

  // ------------------------------------------------------------------------------------
  // EditTable
  // ------------------------------------------------------------------------------------ 
  const createEditTable = () => {
    return (
      <>
        <div className="scroll-form">
          <CustomerForm
            formData={editItem}
            setFormData={setEditItem}
            isEdit={true}
          />
        </div>
        <div className="bottom-button">
          <>
            {selectedItem.contractStatus === CONTRACT_STATUS.ACTIVE && (
              <button className="red" onClick={handleStopSubmit}>
                契約停止
              </button>
            )}
            {selectedItem.contractStatus !== CONTRACT_STATUS.INACTIVE && (
              <button className="blue" onClick={handleEditSubmit}>
                更新
              </button>
            )}
          </>
        </div>
      </>
    )
  }

  // ------------------------------------------------------------------------------------
  // PaymentTable
  // ------------------------------------------------------------------------------------ 
  const createPaymentTable = () => {
    return (
      <>
        <div className="scroll-form">
          <CustomerPaymentForm
            formData={paymentItem}
            setFormData={setPaymentItem}
          />
        </div>
        <div className="bottom-button">
          <button className="blue" onClick={handlePaymentSubmit}>
            PDF出力
          </button>
        </div>
      </>
    )
  }

  // ------------------------------------------------------------------------------------
  // ValidationCheck
  // ------------------------------------------------------------------------------------
  const validateCompanyData = (params, isEdit) => {
    if (!isEdit) {
      // 会社名
      const companyNameResult = Validators.validateCompanyName(params.companyName);
      if (companyNameResult.error) {
        return { message: companyNameResult.message, error: true };
      }
      // 契約開始日
      const contractStartDateResult = Validators.validateContractStartDate(params.contractStartDate);
      if (contractStartDateResult.error) {
        return { message: contractStartDateResult.message, error: true };
      }
    }
    
    // 契約終了日
    const contractEndDateResult = Validators.validateContractEndDate(params.contractEndDate);
    if (contractEndDateResult.error) {
      return { message: contractEndDateResult.message, error: true };
    }
    // 契約開始日 & 契約終了日
    const contractDateResult = Validators.validateContractDate(
      params.contractStartDate,
      params.contractEndDate
    );
    if (contractDateResult.error) {
      return { message: contractDateResult.message, error: true };
    }
    
    if (params.planType === PLAN_TYPE.ENTERPRISE) {
      // 管理者アカウント数
      const enterpriseAdminCountResult = Validators.validateEnterpriseAdminCount(params.enterpriseAdminCount);
      if (enterpriseAdminCountResult.error) {
        return { message: enterpriseAdminCountResult.message, error: true };
      }
      // スタッフアカウント数
      const enterpriseStaffCountResult = Validators.validateEnterpriseStaffCount(params.enterpriseStaffCount);
      if (enterpriseStaffCountResult.error) {
        return { message: enterpriseStaffCountResult.message, error: true };
      }
    }

    // 郵便番号
    const postalCodeResult = Validators.validatePostalCode(params.postalCode);
    if (postalCodeResult.error) {
      return { message: postalCodeResult.message, error: true };
    }
    // 住所1
    const address1Result = Validators.validateAddress1(params.address1);
    if (address1Result.error) {
      return { message: address1Result.message, error: true };
    }
    // 住所2
    const address2Result = Validators.validateAddress2(params.address2);
    if (address2Result.error) {
      return { message: address2Result.message, error: true };
    }
    // 備考
    const noteResult = Validators.validateNote(params.note);
    if (noteResult.error) {
      return { message: noteResult.message, error: true };
    }

    return { message: '', error: false };
  }

  const validateInvoiceData = (params) => {
    // Formからの入力項目ではない項目の必須チェック
    // 会社名
    if(!params.companyName) {
      return { message: 'アカウント設定画面で、会社名を設定してください。', error: true };
    }
    // 適格請求書発行事業者登録番号
    if (!params.businessNumber) {
      return { message: 'アカウント設定画面で、適格請求書発行事業者登録番号を設定してください。', error: true };
    }
    // 住所
    if (!params.companyAddress) {
      return { message: 'アカウント設定画面で、住所を設定してください。', error: true };
    }
    // 振込先
    if (!params.bankAccount) {
      return { message: 'アカウント設定画面で、振込先を設定してください。', error: true };
    }

    // 請求書番号
    const invoiceNumberResult = Validators.validateInvoiceNumber(params.invoiceNumber);
    if (invoiceNumberResult.error) {
      return { message: invoiceNumberResult.message, error: true };
    }
    // 請求書発行日
    const invoiceDateResult = Validators.validateInvoiceDate(params.date);
    if (invoiceDateResult.error) {
      return { message: invoiceDateResult.message, error: true };
    }  

    // 支払期限日は任意項目なので、未入力の場合はエラーなし
    if (params.paymentDue) {
      // 支払期限
      const paymentDueResult = Validators.validatePaymentDue(params.paymentDue);
      if (paymentDueResult.error) {
        return { message: paymentDueResult.message, error: true };
      }

      // 請求書発行日 & 支払期限日
      const invoiceDateAndPaymentDueResult =
        Validators.validateInvoiceDateAndPaymentDueDate(params.date, params.paymentDue);
      if (invoiceDateAndPaymentDueResult.error) {
        return { message: invoiceDateAndPaymentDueResult.message, error: true };
      }
    }

    // 明細
    const invoiceDataDetailResult = Validators.validateInvoiceDetail(params.items);
    if (invoiceDataDetailResult.error) {
      return { message: invoiceDataDetailResult.message, error: true };
    }
    // 消費税
    const taxResult = Validators.validateTax(params.tax);
    if (taxResult.error) {
      return { message: taxResult.message, error: true };
    }
    // 備考
    const noteResult = Validators.validateNote(params.note);
    if (noteResult.error) {
      return { message: noteResult.message, error: true };
    }
    return { message: '', error: false };
  }

  // ------------------------------------------------------------------------------------
  // レンダリング
  // ------------------------------------------------------------------------------------ 
  return (
    <div className="view-contents" id="customer-master">
      <Loading isLoading={isLoading} />
      <BottomSheet ref={bottomSheetRef} />

      <div className="main-contents">
        {size.width > 1200 && (<h2 className="page-title">顧客マスタ</h2>)}
        <div className="header-contents">
          <div className="search-bar">
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                placeholder="顧客名で検索"
                value={mainSearchTerm}
                onChange={(e) => setMainSearchTerm(e.target.value)}
                maxLength={20}
                style={{
                  backgroundImage: 'url(/images/search.png)',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '10px center',
                  backgroundSize: '20px 20px',
                  paddingLeft: '40px',
                  paddingRight: mainSearchTerm ? '30px' : '10px'
                }}
              />
              {mainSearchTerm && (
                <button
                  onClick={onMainClear}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '16px',
                    color: '#878787'
                  }}>
                  ×
                </button>
              )}
            </div>
          </div>
          <div className="button-container">
            <div className="add-new-item-button" onClick={handleAdd}>
              <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                width="18px"
                height="18px"
                viewBox="0 0 24 24"
                aria-labelledby="plusIconTitle"
                stroke="var(--blue-font-color)"
                strokeWidth="1.8"
                strokeLinecap="square"
                strokeLinejoin="miter"
                fill="none"
                color="var(--blue-font-color)"
              >
                <title id="plusIconTitle">新規追加</title>
                <path d="M20 12L4 12M12 4L12 20" />
              </svg>
              <p className="hide-option">新規追加</p>
            </div>
          </div>
        </div>

        <div className="middle-contents">
          <div className="left-contents">
            <FilterButton
              statusFilter={mainStatusFilter}
              handleStatusChange={handleMainStatusChange}
              statusOptions={statusOptions}
            />
            <SortingButton
              sortConfig={mainSortConfig}
              handleSortChange={handleMainSortChange}
              sortOptions={sortOptions}
            />
          </div>
          
          <Pagination
            totalPages={mainLastPage}
            currentPage={mainCurrentPage}
            onPageChange={handleMainPageChange}
            totalItems={mainTotalCount}
          />
        </div>

        {createTable(sortedAndFilteredItems)}
      </div>

      {size.width > 1200 ? (
        <>
          {showAdd && (
            <div className="sub-contents">
              <h2 className="page-title">新規追加</h2>
              {createAddTable()}
            </div>
          )}

          {showEdit && (
            <div className="sub-contents">
              <h2 className="page-title">{selectedItem.companyName}</h2>
              {createEditTable()}
            </div>
          )}

          {showPayment && (
            <div className="sub-contents">
              <h2 className="page-title">請求書発行</h2>
              {createPaymentTable()}
            </div>
          )}
        </>
      ) : (
        <>
          <Modal
            isOpen={showAdd}
            title="新規追加"
            actionButtonText="登録"
            onAction={handleAddSubmit}
            closeButtonText="閉じる"
            closeModal={() => setShowAdd(false)}
          >
            <div className="sub-contents">
              {createAddTable()}
            </div>
          </Modal>
            
          <Modal
            isOpen={showEdit}
            title={selectedItem.companyName}
            actionButtonText="更新"
            onAction={handleEditSubmit}
            actionSubButtonText={(editItem.contractStatus === CONTRACT_STATUS.ACTIVE) &&
              (new Date(editItem.contractEndDate).getTime() !== new Date(today).getTime()) ? "契約停止" : ""}
            onSubAction={handleStopSubmit}
            closeButtonText="閉じる"
            closeModal={() => setShowEdit(false)}
          >
            <div className="sub-contents">
              {createEditTable()}
            </div>
          </Modal>
            
          <Modal
            isOpen={showPayment}
            title='請求書発行'
            actionButtonText="PDF出力"
            onAction={handlePaymentSubmit}
            closeButtonText="閉じる"
            closeModal={() => setShowPayment(false)}
          >
            <div className="sub-contents">
              {createPaymentTable()}
            </div>
          </Modal>
        </>
      )}
    </div>
  )
}

export default CustomerMaster;